import React from 'react'
import statics from '../../../assets/images/articles/delivery/statics.webp'

const LastSection = () => {
    const data = [
        {
            title: "Mobile first",
            description: "On-demand orders typically originate in a mobile app, not a desktop browser. E-commerce businesses that hope to succeed in q-commerce need to adopt a mobile-first strategy."
        },
        {
            title: "Micro-fulfillment",
            description: "Q-commerce thrives on quick delivery of micro-orders, which requires goods close to the customer, typically three km or less. This means shifting away from large exurban distribution centers to small “dark stores” - micro-fulfillment centers with dynamic, fast-moving inventory. Dark stores are replacing the early q-commerce model of having couriers shop at brick-and-mortar outlets, which are ill-equipped for the dash-in/dash-out needs of on-demand delivery."
        },
        {
            title: " Local access points",
            description: "A major enabler of successful quick commerce in dense cities is a network of access points - locker stations or partner businesses - where customers can send, receive, and return parcels, even 24/7. Partnerships with brick-and-mortar retailers for in-store pickup of on-demand orders are another option."
        },
        {
            title: "Two wheels, not four",
            description: "Expectations for reliable on-demand delivery require q-commerce companies to think beyond delivery trucks and cargo vans in favor of scooters and e-bikes, which can more easily zip back and forth between hyper-local dark stores and customer doorsteps or access points."
        },
        {
            title: "Lean workforce",
            description: "Q-commerce typically relies on a quickly scalable, app-driven, round-the-clock fleet of independent “gig” workers using their own vehicles rather than a fixed workforce of drivers in company-branded vehicles."
        }
    ]
    return (
        <div className='container lg:px-[20px] px-4 2xl:p-0 mx-auto mb-8'>
            <div className='flex items-center justify-center flex-col py-6'>
                <h1 className='font-extrabold lg:text-3xl text-center text-2xl text-[#132b38]'>The need for speed: getting on-demand delivery logistics right</h1>
            </div>

            <div className='lg:p-8 md:p-4'>
                <p className='text-gray-500'>Putting the "Q" in q-commerce requires sophisticated supply chain logistics. Here is a peek at some key components:</p>

                <ul className='py-4 lg:pl-8 pl-4'>
                    {
                        data.map((item, index) => (
                            <li key={index} className='list-disc'>
                                <h5 className='font-bold text-[#1374b4] pt-4'>{item.title}:</h5>
                                <p className='text-[#666666] text-justify'>{item.description}</p>
                            </li>
                        ))
                    }
                </ul>
                <div>
                    <div className='flex items-center justify-center flex-col pt-8 pb-4'>
                        <h1 className='font-extrabold lg:text-3xl text-center text-2xl mb-4 text-[#132b38]'>The evolution of commerce</h1>

                        <img src={statics} alt='statics' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LastSection
