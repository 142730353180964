import React from 'react'
import sliderImg from '../../../assets/images/shippingSupport/Transport-reglemente.jpg'

const Slider = () => {
    return (
        <div className='lg:h-[400px] 2xl:h-[450px] h-[180px] bg-cover bg-center' style={{ backgroundImage: `url(${sliderImg})` }}>
        </div>
    )
}

export default Slider
