import React from 'react'
import MainLayout from '../../layouts/MainLayout'
import Slider from '../../componenets/ShippingSupport/Slider'
import BodySection from '../../componenets/ShippingSupport/BodySection'

const ShippingSupport = () => {
    return (
        <MainLayout>
            <Slider />
            <BodySection />
        </MainLayout>
    )
}

export default ShippingSupport
