import React, { useState, useEffect } from 'react';
import sliderImg1 from '../../assets/images/slider/Slidehome2.webp';
import sliderImg2 from '../../assets/images/slider/bgMobile.jpg';

import TrackShipment from './TrackShipment';

const HomeSlider = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className='lg:h-[480px] 2xl:h-[550px] md:h-[450px] h-[320px] z-90 bg-cover bg-center overflow-hidden' style={{ backgroundImage: `url(${windowWidth >= 768 ? sliderImg1 : sliderImg2})` }}>
            <div className='container flex flex-col justify-center max-sm:bg-[#0c0b0b4d] items-center h-[100%] mx-auto'>
                <TrackShipment />
            </div>
        </div>
    );
};

export default HomeSlider;
