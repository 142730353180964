import React from 'react'

const BodySection = () => {
    return (
        <div className='container lg:px-[20px] px-4 2xl:p-0 md:mt-11 mt-8 mx-auto text-[#666666] text-justify'>
            <div className='lg:px-8 md:px-4 '>
                <h2 className='mb-8 text-[#1374b4] font-bold  md:text-2xl text-[20px]'>1- Objet:</h2>
                <p className='mb-4'>Les présentes conditions ont pour objet de définir les modalités d'exécution des prestations de transport de marchandises assurées par IRSALKOM.</p>
                <p className='mb-4'>IRSALKOM s'engage à acheminer les colis dès leur prise en charge jusqu'à la destination convenue selon le trajet,la procédure et les transporteurs qu'elle choisira.</p>
                <p className='mb-4'>Le fait pour Expéditeurs de donner l'ordre d'expédition vaut acceptation sans réserve des présentes conditions.</p>
                <p className='mb-4'>Aucun agent, partenaire ou sous-traitant n'est habilité à en modifier les dispositions.</p>
                <p className='mb-4'>Les relations contractuelles sont régies par les présentes conditions générales, sous réserve de l'éventuelle application de conventions internationales ou de toute autre disposition d'ordre public.</p>
            </div>


            <div className='lg:p-8 md:p-4 pt-4'>
                <h2 className='mb-8 text-[#1374b4] font-bold  md:text-2xl text-[20px]'>2- Restriction Au Transport:</h2>
                <p className='mb-4'>Sont interdites toutes marchandises relevant des réglementations nationales et internationales sur les produits dangereux.</p>
                <p className='mb-4'>Il en est de même pour tous les objets qui, par leur nature ou leur conditionnement, peuvent présenter un danger pour l'environnement humain ou endommager les autres colis transportés.</p>
                <p className='mb-4'>Sont également interdits, sans que cette liste soit limitative, les bijoux, pierres et métaux précieux, les monnaies, devises, billets de banque, les titres de paiement (cartes de crédit, chèques, titres au porteur…), les animaux vivants ou morts, les armes à feu, les stupéfiants, les objets d'art, les publications ou supports audiovisuels interdits par la loi, plantes, aliments, alcool sauf boissons alcoolisées, produits alimentaires subventionnés (sucre, farine, huile de table…).</p>
                <p className='mb-4'>Expéditeur s'engage à informer IRSALKOM des particularités non apparentes de la marchandise quand elles sont susceptibles d'entraîner des répercussions sur le déroulement du transport.</p>
                <p className='mb-4'>Dans l'hypothèse où l'expéditeur confierait à IRSALKOM des objets relevant des exclusions ci-dessus, il reconnaît que ceux-ci voyageraient à ses seuls risques et périls et décharge IRSALKOM de toute responsabilité.</p>

                <p className='mb-4'>En cas d'incident, l'expéditeur autorise IRSALKOM à disposer des colis de la façon qu'elle jugera opportune y compris d'en abandonner l'acheminement.</p>
            </div>

            <div className='lg:px-8 md:px-4 '>
                <h2 className='mb-8 text-[#1374b4] font-bold md:text-2xl text-[20px]'>3- Obligations De L'expediteur :</h2>
                <p className='mb-4'>Contenu : L'expéditeur est responsable des mentions portées sur le bordereau de transport, et notamment de la description de la nature et du contenu de l'envoi ainsi que de l'adresse de destination. La seule indication d'une boîte postale étant insuffisante, il lui appartient de fournir une adresse complète et/ou les renseignements conformes aux usages du pays de destination, afin de permettre une distribution dans des conditions normales. L'expéditeur garantit que l'envoi ne contrevient à aucune disposition légale ou réglementaire en vigueur.</p>

                <p className='mb-4'>Emballage : les colis sont conditionnés par l'expéditeur dans un emballage bien fermé, résistant, approprié au contenu et aux exigences du transport. A défaut, le colis voyage à ses risques et périls.

                </p>
                <p className='mb-4'>Formalités douanières : (transport international) l'expéditeur est tenu de présenter tous documents nécessaires à l'exécution des formalités douanières en se conformant à la réglementation applicable. Il sera tenu au paiement des frais encourus par le destinataire à défaut de règlement par celui-ci.

                </p>
                <p className='mb-4'>IRSALKOM ne saurait être tenue pour responsable de faits ou d'omissions imputables à l'Expéditeur ou au service des douanes.

                </p>
                <p className='mb-4'>Poids : IRSALKOM se réserve le droit de rectifier toute différence de poids ou de volume qu'elle constaterait. L'expéditeur autorise IRSALKOM à procéder à la régularisation des sommes facturées au vu des modifications ainsi obtenues.</p>

                <p className='mb-4'>Valeur : A) Transport international : la valeur des colis ne doit pas dépasser 100 euros, tous colis dont la valeur est supérieure peut être considérée comme High value par la douane et peut être bloquée pour paiement des droit et taxes. Le client est seul responsable de la valeur et toutes informations déclarées lors du dépôt de son colis.</p>

                <p className='mb-4'>B) Transport National : la valeur remboursable d'un colis dont la valeur n'a pas été déclarée au depot du colis est 100 dh par colis. Le client est seul responsable de la valeur et toutes informations déclarées lors du dépôt de son colis.</p>
            </div>


            <div className='lg:px-8 md:px-4 '>
                <h2 className='mb-8 text-[#1374b4] font-bold  md:text-2xl text-[20px]'>4- Droit D'inspection & Formalites Douanieres :</h2>
                <p className='mb-4'>L'Expéditeur accepte que IRSALKOM ou toute autre autorité gouvernementale, y compris les douanes, soit en droit d'ouvrir et d'inspecter les colis confiés à tout moment, sans que l'exercice de ce droit ne remette en cause le fait que l'Expéditeur reste seul responsable de la réalité de ses déclarations.

                </p>
                <p className='mb-4'>Il est conseillé aux Expéditeurs d'envois commerciaux de fournir la nomenclature douanière harmonisée (code SH) afin de permettre à la Douane de mieux traiter ces envois.

                </p>
            </div>

            <div className='lg:px-8 md:px-4 '>
                <h2 className='mb-8 text-[#1374b4] font-bold  md:text-2xl text-[20px]'>5- Livraison:</h2>
                <p className='mb-4'>Lors de la livraison, les dommages ou spoliations doivent faire l'objet de réserves précises, complètes, datées et signées sur le bordereau de livraison.
                </p>
                <p className='mb-4'>La signature numérisée du destinataire ainsi que sa reproduction font preuve de la livraison des colis et les parties reconnaissent à cette signature une valeur identique à celle d'une signature traditionnelle sur papier.
                </p>
            </div>

            <div className='lg:px-8 md:px-4 '>
                <h2 className='mb-8 text-[#1374b4] font-bold  md:text-2xl text-[20px]'>6- Responsabilite:</h2>

                <h3 className='font-bold text-black mb-4'>Perte / avarie</h3>
                <p className='mb-4'>La responsabilité de IRSALKOM peut être engagée en cas de perte, de dommage matériel causé au colis au cours de transport ou de non-livraison, sauf faute de l'expéditeur ou du destinataire, cas de force majeure, vice propre de l'objet, insuffisance d'emballage qui constituent des cas d'exonération.

                </p>
                <p className='mb-4'>La responsabilité de IRSALKOM est engagée pour la valeur de la marchandise déclarée à la date de la prise en charge, le montant de sa réparation ou pour les frais directs de reconstitution des documents.
                </p>

                <h3 className='font-bold text-black mb-4'>Retard</h3>

                <p className='mb-4'>IRSALKOM s'engage à déployer tous ses efforts pour effectuer la prestation dans le délai convenu.
                </p>

                <p className='mb-4'>En cas de retard à la livraison de son fait, sous réserve de l'application de dispositions légales ou réglementaires en vigueur, la responsabilité de IRSALKOM est limitée au prix du transport.
                </p>
                <h3 className='font-bold text-black mb-4'>Préjudice indemnisable</h3>

                <p className='mb-4'>IRSALKOM ne saurait être tenue à la prise en charge du préjudice immatériel ou indirect quelle qu'en soit la cause.
                </p>
            </div>


            <div className='lg:px-8 md:px-4 '>
                <h2 className='mb-8 text-[#1374b4] font-bold  md:text-2xl text-[20px]'>7- Reclamation:</h2>
                <p className='mb-4'>Les réserves détaillées portées par le destinataire sur le bordereau de transport au moment de la livraison doivent être jointes à toute réclamation pour avarie ou perte partielle. A défaut, il appartient au réclamant d'apporter la preuve que le dommage a eu lieu pendant le transport.
                </p>
                <p className='mb-4'>Toute réclamation doit être notifiée par écrit et adressée à IRSALKOM dans un délai de 21 jours suivant la date de livraison prévue pour les expéditions internationales et 7 jours pour les expéditions nationales
                </p>

                <p className='mb-4'>La réclamation doit être motivée et accompagnée des justificatifs du préjudice subi (lettre de transport, facture d'achat …)
                </p>

                <p className='mb-4'>La réclamation est recevable à condition que le prix du transport ait été acquitté.
                </p>
            </div>


            <div className='lg:px-8 md:px-4 '>
                <h2 className='mb-8 text-[#1374b4] font-bold  md:text-2xl text-[20px]'>8- Prix Des Prestations - Conditions De Paiement:</h2>
                <p className='mb-4'>Les prix ne comprennent pas les droits, taxes, redevance et impôts dus en application de toutes réglementations, notamment fiscales et douanières.
                </p>

                <p className='mb-4'>Le règlement s'effectue immédiatement lors de la prise en charge du colis ou à réception de la facture. Aucun escompte ne sera pratiqué en cas de paiement anticipé.
                </p>
            </div>

            <div className='lg:px-8 md:px-4 '>
                <h2 className='mb-8 text-[#1374b4] font-bold  md:text-2xl text-[20px]'>9- Poids Et Dimensions Pris En Charge:</h2>
                <p className='mb-4 font-bold text-black'>Les colis doivent répondre aux normes de poids et dimensions suivant:
                </p>

                <p className='mb-4'>Poids maximum : 31 kg Longueur maximum : 175cm.</p>

                <p className='mb-4'>1 longueur + 2 hauteurs + 2 largeurs ={'<'} 300cm maximum</p>
                <p className='mb-4'>Tout colis d'un poids volumétrique important peut être surtaxé selon les coûts de Transport en vigueur d'IRSALKOM.</p>
            </div>

            <div className='lg:px-8 md:px-4 '>
                <h2 className='mb-8 text-[#1374b4] font-bold  md:text-2xl text-[20px]'>10- Envois Admis En Rebut:</h2>

                <p className='mb-4'>Les envois qui n'ont pas pu être livrés après avoir épuisé toutes les tentatives de distribution, ils seront automatiquement remis à des ONG caritatives.</p>
            </div>

            <div className='lg:px-8 md:px-4 '>
                <h2 className='mb-8 text-[#1374b4] font-bold  md:text-2xl text-[20px]'>11- Loi Applicable:</h2>

                <p className='mb-4'>Le droit applicable est le droit du domicile d'IRSALKOM.</p>
                <p className='mb-4'>Tout litige relatif à l'exécution, à l'interprétation ou à la résiliation du présent contrat relèvera des Tribunaux du domicile d'IRSALKOM.</p>
            </div>

            <div className='lg:px-8 md:px-4 mb-14'>
                <h2 className='mb-8 text-[#1374b4] font-bold  md:text-2xl text-[20px]'>12- Protection Des Données Personnelles:</h2>

                <p className='mb-4'>Par le biais de ce formulaire, IRSALKOM collecte vos données personnelles en vue de traiter vos colis national et international.</p>

                <p className='mb-4'>Les données personnelles collectées peuvent être transmises à des sous-traitants (locaux et étrangers), à l'administration de douanes, les organes de contrôle externe conformément aux lois en vigueurs.</p>
            </div>


        </div>
    )
}

export default BodySection
