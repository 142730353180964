import React from 'react';
import { FaMapMarkedAlt, FaPhoneAlt } from "react-icons/fa";
import { LuCalendarClock } from "react-icons/lu";
import { Link } from 'react-router-dom';

const LocationDetails = ({ activeLocation, locationsData }) => {
    return (
        <div className='lg:pt-14 pt-8'>
            {locationsData[activeLocation]?.city ? (
                <h1 className='text-[#1c83b3] font-bold lg:pb-14 pb-8 lg:text-3xl text-2xl'>{locationsData[activeLocation].city}</h1>
            ) : (
                <h1 className='text-[#1c83b3] font-bold lg:pb-14 pb-8 lg:text-3xl text-2xl'>{activeLocation}</h1>
            )}
            <ul>
                <li className='lg:pb-14 pb-8 flex flex-wrap'>
                    <LuCalendarClock className='text-[#1c83b3] lg:text-[45px] md:text-[35px] text-[30px]' />
                    <div className='md:pl-6 pl-3'>
                        <p className='text-white lg:text-[18px] text-sm font-bold'>Mon-Fri: 9:00 -18:30</p>
                        <p className='text-white lg:text-[18px] text-sm font-bold'>Sat: 9:00 - 14:00</p>
                    </div>
                </li>
                <li className='lg:pb-14 pb-8'>
                    {locationsData[activeLocation]?.phoneNumber && (
                        <div className='flex flex-wrap'>
                            <FaPhoneAlt className='text-[#1c83b3] lg:text-[45px] md:text-[35px] text-[30px]' />
                            <p className='text-white lg:text-[18px] text-sm font-bold md:pl-6 pl-3 pt-4'>{locationsData[activeLocation].phoneNumber}</p>
                        </div>
                    )}
                </li>
                <li>
                    {locationsData[activeLocation]?.addressP1 && (
                        <div className='flex flex-wrap'>
                            <Link to={locationsData[activeLocation].link} target='_blank'>
                                <FaMapMarkedAlt className='text-[#1c83b3] lg:text-[45px] md:text-[35px] text-[30px]' />
                            </Link>
                            <Link to={locationsData[activeLocation].link} target='_blank'>
                                <p className='text-white lg:text-[18px] text-sm font-bold md:pl-6 hover:text-[#07c9e1] pl-3 md:pt-4'>{locationsData[activeLocation].addressP1}<br />
                                    {locationsData[activeLocation].addressP2}
                                </p>
                            </Link>

                        </div>
                    )}
                </li>
            </ul>
        </div>
    );
};

export default LocationDetails;

