import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaSortDown } from "react-icons/fa";
import "/node_modules/flag-icons/css/flag-icons.min.css";

const LanguageSelector = () => {
    const { i18n } = useTranslation();
    const [local, setLocal] = useState(i18n.language);
    const [isOpen, setIsOpen] = useState(false);

    // Update local state when language changes
    i18n.on('languageChanged', (lng) => setLocal(lng));

    // Function to handle language change
    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
        setLocal(language);
        toggleMenu();
    };

    // Function to toggle dropdown menu
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };
    return (
        <>
            <div className="">
                <div className="relative lg:w-auto w-[100px] hover:bg-gray-300 pr-4 pl-3 py-3 rounded" onClick={toggleMenu}>

                    <FaSortDown className=" mt-2 text-[15px] absolute top-1 right-0" />
                    {local === 'en' ? (
                        <p className='text-[#666666] cursor-pointer text-sm'><span className="fi fi-gb"></span> en</p>
                    ) : (
                        <p className='text-[#666666] cursor-pointer text-sm'><span className="fi fi-fr"></span> fr</p>
                    )}
                </div>
                {isOpen && (
                    <ul className="p-4 shadow bg-white lg:w-auto w-[100px] lg:absolute lg:top-14 rounded">
                        <li onClick={() => changeLanguage('en')} className='mb-4'>
                            <p className='text-[#666666] cursor-pointer text-sm'>
                                <span className="fi fi-gb"></span> English
                            </p>
                        </li>
                        <li onClick={() => changeLanguage('fr')}>
                            <p className='text-[#666666] cursor-pointer text-sm'>
                                <span className="fi fi-fr"></span> French
                            </p>
                        </li>


                    </ul>
                )}
            </div>
        </>



    )
}

export default LanguageSelector
