import React from 'react';
import ServicesIntro from '../../data/ServicesIntro';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const ServicesSection = () => {
    const { t } = useTranslation();

    // Get the services array directly
    const services = ServicesIntro();

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4,
            slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 3,
            slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    return (
        <div className='bg-[#171b3e] mt-4'>
            <div className='container lg:px-[20px] px-4 py-8 mx-auto'>
                <div className='mb-8 flex items-center justify-center flex-col text-center'>
                    <h1 className='font-extrabold text-3xl mb-3 text-white'>{t('servicesSection.sectionTitle')}</h1>
                    <hr className='border-[2px] border-[#07c9e1] w-[80px]' />
                </div>
                <div className='lg:p-8 md:py-11'>
                    <Carousel
                        responsive={responsive}
                        ssr={true} // means to render carousel on server-side.
                        infinite={true}
                        removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                        autoPlay={true}
                        autoPlaySpeed={3000}
                        keyBoardControl={true}
                        containerClass="carousel-container"
                    >
                        {services.map((item, index) => (
                            <div className='bg-white h-[280px] flex flex-col items-center justify-center p-2 rounded-lg ml-4' key={index} >
                                <img src={require(`../../assets/icons/homeServices/${item.img}`)} className='pt-2 w-[60px]' alt={item.service} />
                                <h2 className='pt-2 pb-4 font-bold'>{item.service}</h2>
                                <p className='text-justify text-sm text-[#666666]'>{item.description}</p>
                                <Link to={`/${item.link}`} className='italic font-bold text-[#044b70] py-4 hover:text-[#07c9e1] hover:underline'>{t('servicesSection.LearnMoreBtn')}</Link>
                            </div>
                        ))}
                    </Carousel>
                </div>
            </div>
        </div>
    );
};

export default ServicesSection;

