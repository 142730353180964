import React from 'react'
import BeatLoader from "react-spinners/BeatLoader";

const Loading = () => {
    return (
        <div className='container lg:px-[20px] px-4 2xl:p-0 mx-auto flex justify-center items-center h-screen'>
            <BeatLoader
                color="#07c9e1"
                aria-label="Loading Spinner"
                data-testid="loader"
            />

        </div>
    )
}

export default Loading
