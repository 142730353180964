import React from 'react'
import ecomImg from '../../../assets/images/articles/horizonEcommerce/pexels-andrea-piacquadio-3760081.webp'

const Description = () => {
    return (
        <div className='container lg:px-[20px] px-4 2xl:p-0 mx-auto'>
            <div className='flex items-center justify-center flex-col pt-11 pb-4'>
                <h1 className='font-extrabold lg:text-3xl text-center text-2xl text-[#132b38]'>A new e-commerce landscape?</h1>
            </div>
            <div className='lg:p-8 md:p-4 pb-6 text-[#666666] text-justify'>
                <img src={ecomImg} alt='ecomImg' className='rounded md:ml-3 md:w-[50%] w-[100%] h-[200px] lg:h-[350px] my-4 md:my-0 float-right' />
                <p className='mb-4'>You don't have to be a pundit or a visionary to know that the spring of 2020 will have a lasting impact on an entire generation. Lockdowns and stay-at-home orders brought public life to a standstill in many parts of the world. Retail ground to a halt; city centers turned into ghost towns. Plans and forecasts became obsolete overnight.</p>

                <p className='mb-4'>While the virus put the brakes on in-person activity in the physical world, the digital world zoomed ahead at full speed. Remote work became the new norm as people adjusted to virtual meetings and 'commuted' to work from their kitchen tables or spare bedrooms.</p>

                <p className=''>
                    Stuck at home, everyone started clicking the Buy button and online sales skyrocketed, giving the already upward trajectory a significant boost. Today, one in five items is purchased online, compared with just under 14% before the pandemic.
                </p>


            </div>
            <div className='lg:p-8 md:p-4 mb-8 text-[#666666] text-justify'>
                <p>Fast forward three years - in a world bidding the pandemic adieu - and we're staring down the inevitable question: how sustainable was this meteoric rise in 2020 and 2021? We already have the answer: As expected, online sales growth did not maintain its momentum, and brick-and-mortar retail has recovered somewhat. So, the pandemic didn't change all consumer habits. However, a new e-commerce landscape is emerging. But what will it look like? Let's explore the key trends that will shape the future of online retail.</p>
            </div>
        </div>
    )
}

export default Description
