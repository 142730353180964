import React from 'react'
import { useLocation } from 'react-router-dom';
import ContentSection from './ContentSection'
import bgImg from '../../assets/images/track/380_generated.jpg'
import { Link } from 'react-router-dom'

const TrackResults = () => {
    const location = useLocation();
    const { results: data, number: track } = location.state || {}; // Accessing state from location


    return (
        <div className=''>
            <div className='container lg:px-[20px] px-4 2xl:p-0 2xl:mt-20 mt-16 py-14 2xl:py-11 mx-auto'>
                <div className='w-[100%]'>
                    <div className='mb-8 flex items-center justify-center flex-col text-center'>
                        <h1 className='font-extrabold md:text-3xl text-2xl text-[#132b38]'>Package Tracking Status</h1>
                        <h1 className='font-extrabold md:text-2xl text-1xl mt-3 text-[#1374b4]'>{track}</h1>

                    </div>
                    <div className='flex flex-wrap lg:p-8 md:p-4'>

                        <div className='lg:w-[50%] w-[100%] py-4 lg:rounded-l-lg lg:rounded-none rounded-lg shadow-lg'>
                            <ul className='flex flex-col items-center justify-center'>
                                {data.map((item, index) => (
                                    <ContentSection key={index} data={item} />
                                ))}
                            </ul>
                        </div>
                        <div className='w-[50%] shadow-lg lg:rounded-r-lg  hidden lg:block'>
                            <img src={bgImg} alt='tracking' className='h-[100%] w-[100%] rounded-r-lg' />
                        </div>
                    </div>

                    <div className='flex items-center mt-6 lg:mt-0 justify-center'>
                        <Link to="/" className='font-bold md:px-4 px-2 lg:py-2 shadow-lg py-1 hover:border-[1px] hover:border-[#07c9e1] hover:bg-white bg-[#07c9e1] text-white hover:text-[#07c9e1] rounded'>back to home page</Link>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default TrackResults
