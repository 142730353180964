import React from 'react'
import SocialMediaImg from '../../../assets/images/articles/Trends/social-media-concept-with-smartphone.jpg'

const BodySection = () => {
    return (
        <div className='container lg:px-[20px] px-4 2xl:p-0 mx-auto'>
            <div className='flex items-center justify-center flex-col lg:p-8 md:p-4 md:py-0 py-4'>
                <h1 className='font-extrabold lg:text-3xl text-center text-2xl text-[#132b38]'>The rise of 'social commerce' and virtual marketplaces</h1>
            </div>
            <div className='lg:flex lg:flex-wrap lg:p-8 md:p-4 pb-6'>

                <div className='py-3 lg:hidden '>
                    <img src={SocialMediaImg} alt='Social Media' className='rounded h-[300px] w-[100%] object-cover' />
                </div>
                <div className='lg:w-[50%] md:pr-4 text-[#666666] text-justify'>
                    <p className='pb-4 pt-4'>Consumers are moving away from completing online purchases on corporate websites. In fact, according to research firm Forrester, 67 percent of B2C online sales will take place on virtual marketplaces by 2022.</p>

                    <p className='pb-4'>Until fairly recently, it was mostly brands, distributors, and retailers who would sell us the things that we buy online. Now, however, it's not just the well-known marketplaces such as Amazon, Alibaba, eBay, and Etsy which have most of the power. New players - the Instagrams and Pinterests of the world - are enabling social commerce that allows smaller businesses to sell online. It's a massive shift, and there are implications for larger corporate businesses now that SMEs are able to access the market and sell online.</p>

                    <p className='pb-4'>
                        Social commerce is where social media meets ecommerce, and it's the social networks providing the conduit to online transactions.
                    </p>
                </div>

                <div className='w-[50%] hidden lg:block'>
                    <img src={SocialMediaImg} alt='Social Media' className='rounded h-[390px] w-[100%] object-cover' />
                </div>
            </div>
            <div className='flex items-center justify-center flex-col lg:p-8 md:p-4 md:py-0 py-4'>
                <h1 className='font-extrabold lg:text-3xl text-center text-2xl text-[#132b38]'>Tackling labor shortages through innovation</h1>
            </div>

            <div className='lg:px-8 md:px-4 my-4 text-[#666666] text-justify'>
                <p className='pb-4'>The ecommerce boom is one factor contributing to a shortage of labor in the logistics industry, particularly during peak seasons. With peak days (think Black Friday) representing as much as 30% of annual volume for some retailers, it's crucial that orders are fulfilled on time. Otherwise, business risk losing valuable customer sentiment and a potentially returning customer.</p>

                <p className='pb-4'>Helping to mitigate that labor shortage is the increasing use of automation in warehouses and distribution centers, which can provide productivity improvements.</p>
            </div>

        </div>
    )
}

export default BodySection
