import React, { lazy } from 'react'
import MainLayout from '../../layouts/MainLayout'

const AboutSlider = lazy(() => import('../../componenets/About/AboutSlider'));
const Description = lazy(() => import('../../componenets/About/Description'));
const Features = lazy(() => import('../../componenets/About/Features'));
const FAQ = lazy(() => import('../../componenets/About/FAQ'));
const Goals = lazy(() => import('../../componenets/About/Goals'));
const Areas = lazy(() => import('../../componenets/About/Areas'));


const About = () => {
    return (
        <MainLayout>
            <AboutSlider />
            <Description />
            <Areas />
            <Goals />
            <Features />
            <FAQ />
        </MainLayout>
    )
}

export default About
