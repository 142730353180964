import { useTranslation } from 'react-i18next'

const ServicesIntro = () => {
    const { t } = useTranslation();
    const data = [
        {
            "service": t('servicesSection.service1title'),
            "description": t('servicesSection.service1description'),
            "img": "delivery-bike.png",
            "link": "National"
        },
        {
            "service": t('servicesSection.service2title'),
            "description": t('servicesSection.service2description'),
            "img": "cash-on-delivery.png",
            "link": "National"
        },
        {
            "service": t('servicesSection.service3title'),
            "description": t('servicesSection.service3description'),
            "img": "warehouse.png",
            "link": "National"
        },
        {
            "service": t('servicesSection.service4title'),
            "description": t('servicesSection.service4description'),
            "img": "air-freight.png",
            "link": "International"
        },
        {
            "service": t('servicesSection.service5title'),
            "description": t('servicesSection.service5description'),
            "img": "transportation.png",
            "link": "International"
        },
        {
            "service": t('servicesSection.service6title'),
            "description": t('servicesSection.service6description'),
            "img": "truck.png",
            "link": "National"
        },
        {
            "service": t('servicesSection.service7title'),
            "description": t('servicesSection.service7description'),
            "img": "forwarding.png",
            "link": "International"
        },
        {
            "service": t('servicesSection.service8title'),
            "description": t('servicesSection.service8description'),
            "img": "sourcing.png",
            "link": "National"
        },
        {
            "service": t('servicesSection.service9title'),
            "description": t('servicesSection.service9description'),
            "img": "lockers.png",
            "link": "National"
        }
    ];
    return data;

}

export default ServicesIntro;