import React from 'react'
import ExploreContent from './Drodawn/ExploreContent'
import FlyoutLink from './Drodawn/FlyoutLink'
import { useTranslation } from 'react-i18next'

const ExploreLgScreen = () => {
    const { t } = useTranslation();
    return (
        <div className="cursor-pointer hover:text-[#1374b4] mr-3 font-bold px-3">
            <FlyoutLink Content={ExploreContent}>
                {t('navbar.explore')}
            </FlyoutLink>
        </div>
    )
}

export default ExploreLgScreen
