import React from 'react'
import vegetablesImg from '../../../assets/images/news/blog1.jpg'

const Description = () => {
    return (
        <div className='container lg:px-[20px] px-4 2xl:p-0 lg:mt-16 mt-8 mx-auto'>
            <div className='flex items-center justify-center flex-col text-center md:p-4'>
                <h1 className='font-extrabold md:text-3xl text-2xl md:mb-4 mb-8 text-[#132b38]'>Mauritania vows to address tax increases on Moroccan agricultural imports</h1>
            </div>


            <div className="lg:p-8 md:p-4 text-justify text-[#666666]" >
                <p className='mb-4 leading-loose'>
                    The issue of Mauritanian tax increases on Moroccan agricultural imports, implemented in January 2024, took center stage during the Monday meeting in Rabat between Morocco's Foreign Minister Nasser Bourita and his counterpart from Mauritania Mohamed Salem Ould Merzoug.
                </p>
                <p className='mb-4 leading-loose'>"Both countries remain committed to resolving any outstanding issues, including the recent customs hikes," affirmed Ould Merzoug to the press. "We believe any disputes can be settled through open dialogue and cooperation".</p>

                <p className='mb-4 leading-loose'>The Mauritanian government justified the three-month tax increase as a measure to "protect domestic products", prompting concerns from Moroccan exporters and the Consumer Forum, who warned of potential price rises for vegetables and fruits in Mauritania.</p>

                <p className='mb-4 leading-loose'>While the Rabat-Nouakchott customs dispute unfolds, it's worth noting that Algeria recently sent a shipment of 25 tons of onions to Mauritania.</p>

            </div>


            <div className="lg:px-8 md:px-4 mb-11 text-justify" >
                <img src={vegetablesImg} alt=' vegetables' className="float-left w-[100%] h-[240px] object-cover md:w-[50%]  md:pr-4 mb-4 md:mb-0" />

                <p className='mb-4 leading-loose text-[#666666]'>
                    The prices of Moroccan fruits and vegetables transported through the El Guerguerat border crossing to Mauritania have surged as the latter's government raised customs taxes on Moroccan produce. The increased prices of Moroccan goods have been noticeable since the beginning of 2024, and consumers are getting worried. The unexpected increase in customs taxes to as much as 171% has raised concerns for the Mauritanian Consumer Forum, which has strongly condemned this measure.
                </p>

                <p className='mb-4 leading-loose text-[#666666]'>
                    The forum argued: "We condemn this measure, which aggravates concerns and erodes the purchasing power of the consumer, already burdened by the continuous rise in prices, the monopoly, and high prices." It also emphasized the need to strike a balance between supporting homegrown items and providing essential consumer goods, to ensure the purchasing power of Mauritanian low-income consumers.
                </p>


            </div>
        </div >
    )
}

export default Description
