import React from 'react'
import differ from '../../../assets/images/articles/logistics/KeyDifferences.png'

const Differences = () => {
    return (
        <div className='container lg:px-[20px] px-4 2xl:p-0 mx-auto'>
            <div className='flex items-center justify-center flex-col'>
                <h1 className='font-extrabold lg:text-3xl text-center text-2xl text-[#132b38]'>3PL vs. 4PL: Key differences</h1>
                <img src={differ} alt='differ' className='py-8' />
            </div>
        </div>
    )
}

export default Differences
