import React from 'react'
import MainLayout from '../../../layouts/MainLayout'
import Slider from '../../../componenets/Articles/Metaverse/Slider'
import Description from '../../../componenets/Articles/Metaverse/Description'
import Explain from '../../../componenets/Articles/Metaverse/Explain'
import LastSection from '../../../componenets/Articles/Metaverse/LastSection'


const Metaverse = () => {
    return (
        <MainLayout>
            <Slider />
            <Description />
            <Explain />
            <LastSection />
        </MainLayout>
    )
}

export default Metaverse
