import React from 'react'
import sliderImg from '../../../assets/images/articles/E_commerce_c2c/shopping.webp'

const Slider = () => {
    return (
        <div className='md:h-[400px] 2xl:h-[500px] h-[300px] bg-cover bg-center relative' style={{ backgroundImage: `url(${sliderImg})` }}>
            <div className='container lg:px-[20px] px-4 2xl:p-0 mx-auto flex flex-wrap'>
                <div className='w-[55%] hidden lg:block'></div>

                <div className='lg:w-[45%] md:w-[80%] flex flex-col text-white lg:p-8 md:px-4 md:pt-[20%] lg:pt-[10%]  pt-[30%]'>
                    <h1 className='font-extrabold lg:text-3xl md:text-2xl text-[18px]'>C2C E-commerce: A look at the growing consumer-to-consumer market?</h1>

                    <p className='md:pt-11 pt-8 text-sm 2xl:text-base font-bold'>This multi-billion-dollar (and growing) market is proving that secondhand is by no means second best. Here's what you need to know.</p>
                </div>
            </div>
        </div>
    )
}

export default Slider
