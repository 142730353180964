import React from 'react'
import MainLayout from '../../../layouts/MainLayout'
import Slider from '../../../componenets/ShippingSupportArticles/DangerousGoods/Slider'
import ContentSection from '../../../componenets/ShippingSupportArticles/DangerousGoods/ContentSection'
import ListDangerous from '../../../componenets/ShippingSupportArticles/DangerousGoods/ListDangerous'


const DangerousGoods = () => {
  return (
    <MainLayout>
      <Slider />
      <ContentSection />
      <ListDangerous />
    </MainLayout>
  )
}

export default DangerousGoods
