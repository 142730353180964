import React from 'react'
import statics from '../../../assets/images/articles/horizonEcommerce/chart-1.webp'

const Growth = () => {
    return (
        <div className='container lg:px-[20px] px-4 2xl:p-0 mx-auto'>
            <div className='flex items-center justify-center flex-col'>
                <h1 className='font-extrabold lg:text-3xl text-center text-2xl text-[#132b38]'>Retail e-commerce sales growth worldwide 2015-2026</h1>
                <img src={statics} alt='static' className='py-8' />
            </div>
        </div>
    )
}

export default Growth
