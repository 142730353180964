import React from 'react'
import sliderImg from '../../assets/images/news/trade_logistics_unctad.jpg'
const NewsSlider = () => {
    return (
        <div className='md:h-[400px] h-[240px] bg-cover bg-center' style={{ backgroundImage: `url(${sliderImg})` }}>

        </div>
    )
}

export default NewsSlider
