import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons'
import { Link } from 'react-router-dom';

const SocialMedia = () => {
    let src = [
        { link: 'https://web.facebook.com/irsalkom', icon: faFacebook },
        { link: 'https://www.instagram.com/irsalkom/', icon: faInstagram },
        { link: 'https://www.tiktok.com/@irsalkom', icon: faTiktok },

    ]
    return (
        <div className='pt-10 pb-3 '>

            {
                src.map((link, index) => (
                    <Link to={link.link} target='_blank' rel="noopener noreferrer" key={index}>
                        <FontAwesomeIcon className="text-2xl pr-4 hover:text-[#07c9e1]" icon={link.icon} />
                    </Link>
                ))
            }


        </div>
    )
}

export default SocialMedia
