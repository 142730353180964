import React from 'react'
import sliderImg from '../../../assets/images/articles/Trends/social-media.webp'

const Slider = () => {
    return (
        <div className='md:h-[450px] 2xl:h-[550px] h-[350px] bg-cover bg-center relative' style={{ backgroundImage: `url(${sliderImg})` }}>
            <div className='container lg:px-[20px] px-4 2xl:p-0 mx-auto flex flex-wrap'>

                <div className='lg:w-[50%] md:w-[80%] flex flex-col text-white lg:p-8 md:px-4 md:pt-[20%] lg:pt-[13%] pt-[35%]'>
                    <h1 className='font-extrabold lg:text-3xl md:text-2xl text-[20px]'>Four key trends shaping the future of E-commerce?</h1>

                    <p className='md:pt-11 pt-8 text-sm 2xl:text-base font-bold'>Ecommerce - and the supply chain that keeps it moving - is evolving, both in the B2B sector and in B2C. Whether you click 'buy' at work or at home, here are four trends that we're likely to see more of.</p>
                </div>

                <div className='w-[50%] hidden lg:block'></div>
            </div>
        </div>
    )
}

export default Slider
