import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Home from './pages/Home/Home';
import About from './pages/About/About';
import National from './pages/National/National'
import International from './pages/International/International'
import Contact from './pages/Contact/Contact';
import Prices from './pages/prices/Prices'
import TrackingStatus from "./pages/TrackingStatus/TrackingStatus";
import TermsOfSales from "./pages/TermsOfSales/TermsOfSales";

// Regions 
import Region from "./pages/Region/Region";

// Blogs
import Blog from "./pages/Blog/Blog";
import Delivery from "./pages/Blog/Articles/Delivery";
import Locker from "./pages/Blog/Articles/Locker";
import HorizonEcommerce from "./pages/Blog/Articles/HorizonEcommerce";
import Logistics from "./pages/Blog/Articles/Logistics";
import EcommerceC2c from './pages/Blog/Articles/EcommerceC2c'
import Metaverse from './pages/Blog/Articles/Metaverse'
import Trends from './pages/Blog/Articles/Trends'
import Packaging from './pages/Blog/Articles/Packaging'

// News 
import News from './pages/News/News'
import News1 from "./pages/News/News1";
import News2 from "./pages/News/News2";
import News3 from "./pages/News/News3";

import ShippingSupport from "./pages/Shipping_Support/ShippingSupport";
import DangerousGoods from "./pages/Shipping_Support/Articles/DangerousGoods";
import PackGoods from "./pages/Shipping_Support/Articles/PackGoods";
import NotFound from './pages/NotFound/NotFound'


const App = () => {
  return (

    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/About" element={<About />} />
        <Route path="/National" element={<National />} />
        <Route path="/International" element={<International />} />
        <Route path="/Prices" element={<Prices />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Terms-Of-Sales" element={<TermsOfSales />} />
        <Route path="/Blog" element={<Blog />} />
        <Route path="/Tracking-status" element={<TrackingStatus />} />
        <Route path="/News" element={<News />} />
        <Route path="/News/News1" element={<News1 />} />
        <Route path="/News/News2" element={<News2 />} />
        <Route path="/News/News3" element={<News3 />} />
        <Route path='/Blog/Getting-on-demand-delivery' element={<Delivery />} />
        <Route path='/Blog/smart-parcel-lockers' element={<Locker />} />
        <Route path="/Blog/what's-on-the-horizon-for-e-commerce" element={<HorizonEcommerce />} />
        <Route path="/Blog/logistics-3pl-4pl" element={<Logistics />} />
        <Route path="/Blog/c2c-e-commerce" element={<EcommerceC2c />} />
        <Route path="/Blog/Metaverse" element={<Metaverse />} />
        <Route path="/Blog/E-commerce-key-trends" element={<Trends />} />
        <Route path="/Blog/E-commerce-packaging" element={<Packaging />} />
        <Route path='/Morocco/:location' element={<Region />} />

        <Route path="/shipping_support" element={<ShippingSupport />} />
        <Route path="/shipping_support/marchandises-dangereuses" element={<DangerousGoods />} />
        <Route path="/shipping_support/Bien-emballer-colis" element={<PackGoods />} />

        {/* 404 Route */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
