import React, { useState } from 'react'
import locationsData from '../../data/locationsData.json'
import LocationDetails from './LocationDetails'
import RegionData from '../../data/RegionData.json'
import { useTranslation } from 'react-i18next'

const MoroccanMap = () => {

    const { t } = useTranslation();

    let [activeLocation, setActiveLocation] = useState('Souss-Massa');

    const handleLocationClick = (location) => {
        // Set the URL of the destination page
        const destinationUrl = `/Morocco/${location}`;
        // Navigate to the destination page
        window.location.href = destinationUrl;

    }
    const handleMouseOver = (location) => {
        setActiveLocation(location);
    };

    return (

        <div className='bg-[#171b3e] pt-8 w-[100%] overflow-hidden'>
            <div className="container w-[100%] lg:px-[20px] px-4 2xl:p-0 mx-auto">

                <div className='w-[100%] grid md:grid-cols-2'>


                    <div className='lg:p-8 md:p-4'>
                        <h1 className='text-white lg:text-4xl text-3xl font-bold'>{t('MoroccanMapSection.sectionTitleP1')} <span className='text-[#1c83b3]'>{t('MoroccanMapSection.sectionTitleP2')}</span></h1>
                        <LocationDetails activeLocation={activeLocation} locationsData={locationsData} />
                    </div>

                    <div className='lg:p-8 hidden lg:block'>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="1000"
                            height="500"
                            viewBox="0 0 3500 1900"
                        >
                            <g className='relative'>
                                {
                                    RegionData.map((region, index) => (
                                        <React.Fragment key={index}>
                                            <path className={` cursor-pointer`}
                                                d={region.path}
                                                fill={activeLocation === region.regionName ? '#0d5c8a' : '#1274b2'}
                                                stroke="black"
                                                onMouseOver={() => handleMouseOver(region.regionName)}
                                                onClick={() => handleLocationClick(region.regionName)}
                                            />
                                            {activeLocation === region.regionName && (
                                                <text
                                                    x={region.x}
                                                    y={region.y}
                                                    fill="white"
                                                    className='text-[40px] font-bold bg-black'
                                                >
                                                    {activeLocation}
                                                </text>
                                            )}
                                        </React.Fragment>
                                    ))

                                }
                            </g>
                        </svg>
                    </div>


                    <div className='md:p-4 hidden lg:hidden md:block'>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="1060"
                            height="400"
                            viewBox="0 0 5700 1999"
                        >
                            <g className='relative'>
                                {
                                    RegionData.map((region, index) => (
                                        <React.Fragment key={index}>
                                            <path className={` cursor-pointer`}
                                                d={region.path}
                                                fill={activeLocation === region.regionName ? '#0d5c8a' : '#1274b2'}
                                                stroke="black"
                                                onMouseOver={() => handleMouseOver(region.regionName)}
                                                onClick={() => handleLocationClick(region.regionName)}
                                            />
                                            {activeLocation === region.regionName && (
                                                <text
                                                    x={region.x}
                                                    y={region.y}
                                                    fill="white"
                                                    className='text-[40px] font-bold bg-black'
                                                >
                                                    {activeLocation}
                                                </text>
                                            )}
                                        </React.Fragment>
                                    ))

                                }
                            </g>
                        </svg>
                    </div>


                    <div className='md:p-4 md:hidden'>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="1050"
                            height="400"
                            viewBox="0 0 5990 1995"
                        >
                            <g className='relative'>
                                {
                                    RegionData.map((region, index) => (
                                        <React.Fragment key={index}>
                                            <path className={` cursor-pointer`}
                                                d={region.path}
                                                fill={activeLocation === region.regionName ? '#0d5c8a' : '#1274b2'}
                                                stroke="black"
                                                onMouseOver={() => handleMouseOver(region.regionName)}
                                                onClick={() => handleLocationClick(region.regionName)}
                                            />
                                            {activeLocation === region.regionName && (
                                                <text
                                                    x={region.x}
                                                    y={region.y}
                                                    fill="white"
                                                    className='text-[40px] font-bold bg-black'
                                                >
                                                    {activeLocation}
                                                </text>
                                            )}
                                        </React.Fragment>
                                    ))

                                }
                            </g>
                        </svg>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default MoroccanMap;
