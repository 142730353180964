import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SendEmail = async (formData) => {
    try {
        const response = await fetch('http://localhost:4000/send-email', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        });

        if (!response.ok) {
            toast.error('Failed to send email', { position: "bottom-right" });
            throw new Error('Failed to send email');
        } else {
            toast.success('Email sent successfully', { position: "bottom-right" });
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error('Error:', error);
        throw error; // Propagate the error back to the caller
    }
}

export default SendEmail
