import React from 'react'
import MainLayout from '../../../layouts/MainLayout'
import Slider from '../../../componenets/Articles/E_commerce_c2c/Slider'
import Desciption from '../../../componenets/Articles/E_commerce_c2c/Desciption'
import Explain from '../../../componenets/Articles/E_commerce_c2c/Explain'
import BodySection from '../../../componenets/Articles/E_commerce_c2c/BodySection'
import LastSection from '../../../componenets/Articles/E_commerce_c2c/LastSection'

const EcommerceC2c = () => {
  return (
    <MainLayout>
      <Slider />
      <Desciption />
      <Explain />
      <BodySection />
      <LastSection />
    </MainLayout>
  )
}

export default EcommerceC2c
