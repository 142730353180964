import React, { Suspense } from 'react'
import Navbar from '../componenets/common/Navbar'
import Footer from '../componenets/common/Footer'
import Loading from '../componenets/common/Loading'

const MainLayout = ({ children }) => {

    return (
        <div className='relative'>
            <Suspense fallback={<Loading />}>
                <Navbar />
                <main>{children}</main>
                <Footer />
            </Suspense>
        </div>
    )
}

export default MainLayout
