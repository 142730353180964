import React from 'react'
import { Link } from 'react-router-dom'

const Details = () => {
    return (
        <div className='container lg:px-[20px] px-4 2xl:p-0 mx-auto'>
            <div className='flex items-center justify-center flex-col pb-4'>
                <h1 className='font-extrabold lg:text-3xl text-center text-2xl text-[#132b38]'>Post-pandemic predictions</h1>
            </div>

            <div className='lg:p-8 md:p-4 text-[#666666] text-justify'>
                <p className='py-4 lg:pt-0 mb-4'>Germany, one of the largest and most important markets, provides an interesting case study. While B2C online retail sales increased by 23% in 2020 and 19% in 2021, the trend has slowed significantly. According to  <Link to="https://www.statista.com/outlook/emo/ecommerce/worldwide?currency=usd" target='_blank' className='text-[#07c9e1] underline'>Statista</Link>, after experiencing negative growth of -2.5% in 2022, the German market is expected to rebound to approximately 5.7% in 2023.</p>

                <p className='py-4 lg:pt-0 mb-4'>
                    But this doesn't appear to be solely a result of a post-pandemic return to normal. Factors such as geopolitical tensions, the energy crisis, and rising inflation are making consumers more cautious, especially in the fashion and tech sectors. Moreover, we're seeing a trend toward fuller online shopping carts and fewer returns affecting parcel volumes. People are saving for their next vacation rather than splurging on a new pair of jeans.
                </p>

                <p className='py-4 lg:pt-0 mb-4'>
                    Still, <Link to='https://www.mckinsey.com/de/news/presse/2022-11-24-pakete' target='_blank' className='text-[#07c9e1] underline'>McKinsey</Link> projected that the average person in Germany will receive about 40 parcels in 2023, up from 24 in 2018. And they forecast a rise to 50 by 2026. But the years 2020 and 2021 may be skewing these predictions. The outlook now is much more uncertain.
                </p>
            </div>
        </div>
    )
}

export default Details
