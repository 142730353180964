import React from 'react'
import { useTranslation } from 'react-i18next'
import ShippingData from '../../data/ShippingData'
import delivery from '../../assets/images/HomePage/14.png'

const ShipingOptions = () => {
    const { t } = useTranslation();
    const data = ShippingData();

    return (
        <div className='mt-8'>
            <div className='container lg:px-[20px] px-4 2xl:p-0 mx-auto'>
                <div className='pb-8 flex items-center justify-center flex-col text-center'>
                    <h1 className='font-extrabold md:text-3xl text-2xl mb-4 text-[#132b38]'>{t('shipingOptions.heading')}</h1>
                    <hr className='border-[2px] border-[#07c9e1] w-[80px]' />
                </div>

                <div className='flex'>
                    <div className='w-[60%] h-[550px] lg:flex items-center justify-center hidden'>
                        <img src={delivery} className=' h-[100%]' />
                    </div>
                    <div className='pt-8 lg:pt-11 2xl:pt-14 lg:w-[40%] w-[100%]'>

                        {
                            data.map((item, index) => (
                                <div className='flex mb-14 2xl:mb-8' key={index}>
                                    <div className=''>
                                        <img src={require(`../../assets/icons/shippingOptions/${item.img}`)}
                                            alt={item.title} className='md:w-[70px] w-[50px]' />
                                    </div>

                                    <div className='pl-4'>
                                        <h3 className='font-extrabold my-2 '>{item.title}</h3>
                                        <p className='text-[#6a6969]'>{item.description}</p>
                                    </div>
                                </div>
                            ))
                        }
                    </div>

                </div>


            </div>

        </div>
    )
}

export default ShipingOptions
