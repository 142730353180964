import React from 'react'
import metaImg from '../../../assets/images/articles/Metaverse/woman-wearing-virtual-reality-simulator.jpg'

const Description = () => {
    return (
        <div className='container lg:px-[20px] px-4 2xl:p-0 mx-auto'>
            <div className='flex items-center justify-center flex-col pt-11 pb-4'>
                <h1 className='font-extrabold lg:text-3xl text-center text-2xl text-[#132b38]'>The science (fiction) of virtual shopping</h1>
            </div>

            <div className='lg:flex lg:flex-wrap lg:p-8 md:p-4 pb-6'>

                <div className='lg:w-[50%] h-auto lg:pr-6 py-3 lg:py-0'>
                    <img src={metaImg} alt='meta' className='rounded md:h-[400px] h-[300px] w-[100%] object-cover' />
                </div>

                <div className='lg:w-[50%] text-[#666666] text-justify'>
                    <p className='mb-4'>Can you believe ecommerce has been around since the early 90s? That's right. We've been shopping online since hip hop and oversized plaid shirts came on the scene - and in much the same way these past 30 years. Interestingly enough, the now much-hyped metaverse has also been around for three decades - at least in the world of science fiction. The term first came to light in Neal Stephenson's  Snow Crash , a cyberpunk novel from the early 1990s. The story takes place in a bleak dystopian future where the protagonists flee to the parallel metaverse to escape from the real world.</p>

                    <p className='mb-4'>Our ecommerce experience has changed significantly since the early days. Broadband internet made browsing ecommerce shops much easier; smartphones, apps, and responsive websites encouraged the shift to mobile ecommerce (also known as m-commerce); and new buy-now, pay later options prompted more of us to click the buy button. But all of that just might feel, well, 20 th  century once the metaverse takes off. This brave new virtual world has the potential to transform ecommerce from two-dimensional online shops to a fully immersive, 3D experience.</p>
                </div>

            </div>
        </div>
    )
}

export default Description
