import React from 'react'
import staticImg from '../../../assets/images/articles/E_commerce_c2c/statistique.png'

const LastSection = () => {
    return (
        <div className='container lg:px-[20px] px-4 2xl:p-0 mx-auto'>
            <div className='flex items-center justify-center flex-col lg:px-8 md:p-4 pt-4'>
                <h1 className='font-extrabold lg:text-3xl text-center text-2xl text-[#132b38]'>Examples of consumer-to-consumer e-commerce</h1>
            </div>
            <div className='lg:p-8 md:p-4 text-[#666666] text-justify'>
                <p className='py-4 md:py-0'>We've already named several examples of C2C e-commerce marketplaces, but how do these sites work? What's the business model? Traditionally C2C sites have been free to buyers, following eBay's lead. Today there are a variety of business models to monetize C2C platforms, most of which are still free to buyers. But even that has changed in recent years.</p>
            </div>


            <div className='flex items-center justify-center flex-col lg:px-8 md:p-4 pt-4'>
                <h1 className='font-extrabold lg:text-3xl text-center text-2xl text-[#132b38]'>Is there room for growth in C2C e-commerce?</h1>
            </div>
            <div className='lg:p-8 md:p-4 py-6 text-[#666666] text-justify'>
                <p className='pb-4'>Will the trend continue? The experts think so. The market for reselling - also known as recommerce - is expected to grow from €75 billion in 2021 to €120 billion by 2025. That's because more and more big players across all e-commerce categories are getting into the C2C game, and consumers of all ages - especially the youngest - are becoming big fans.</p>

                <p className='pb-4'>Fashion is currently the fastest-growing sector in C2C e-commerce, followed closely by family goods such as toys. Well-known European fashion e-tailers like Zalando and NA-KD have already tapped into the potential of C2C, giving their customers the option to resell their “pre-loved” items on their own integrated C2C platforms.</p>

                <p className='pb-4'>As buying options increase, so does the pool of buyers. Although of all ages buy and sell consumers used items online, Gen Z will probably top the C2C charts. Some 32% shop online at least once daily, and they could account for 47% of the C2C market volume by 2025. Millennials, who are on track to lead ecommerce sales globally in 2023, will also be huge C2C sales drivers. That's right, the generation of kids who grew up hanging out at the mall is today's biggest online shopping demographic.</p>

                <div className='flex items-center justify-center flex-col'>
                    <img src={staticImg} alt='static' className='md:py-8 py-4' />
                </div>


                <div className='flex items-center justify-center flex-col lg:px-8 md:p-4 pt-4 py-0'>
                    <h1 className='font-extrabold lg:text-3xl text-center text-2xl text-[#132b38]'>A model for even more growth</h1>
                </div>
                <div className='lg:pt-8 pt-4 text-[#666666] text-justify'>
                    <p className='pb-4'>As the consumer-to-consumer market grows, we're seeing a shift towards the buyer-fee model - a move that is flipping C2C e-commerce on its head. The traditional model targeted buyers: Attract as many buyers to your site as possible, and the sellers will naturally show up and pay the listing fees. But now, as C2C e-commerce companies discover the benefit of more inventory, we're seeing more examples of the buyer-fee model. By reducing barriers for sellers, the site attracts more and naturally increases inventory. In turn, the wider selection of products brings in more buyers.</p>

                    <p className='pb-4'>The success of this model can be seen at the leading secondhand fashion platform Vinted - where sellers list items for free. Vinted is one of the hottest C2C e-commerce sites on the market, which is a massive indication that this model works. </p>
                </div>
            </div>
        </div>
    )
}

export default LastSection
