import React from 'react'
import MainLayout from '../../../layouts/MainLayout'
import Slider from '../../../componenets/Articles/Locker/Slider'
import Description from '../../../componenets/Articles/Locker/Description'
import Benifits from '../../../componenets/Articles/Locker/Benifits'
import LastSection from '../../../componenets/Articles/Locker/LastSection'

const Locker = () => {
    return (
        <MainLayout>
            <Slider />
            <Description />
            <Benifits />
            <LastSection />
        </MainLayout>
    )
}

export default Locker
