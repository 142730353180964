import React from 'react'
import sliderImg from '../../assets/images/slider/bannar.webp'

const BlogSlider = () => {

    return (

        <div className='md:h-[450px] h-[250px] bg-cover bg-center' style={{ backgroundImage: `url(${sliderImg})` }}>

        </div>
    )
}

export default BlogSlider