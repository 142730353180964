import React from 'react'
import { Link } from 'react-router-dom'
import logistic from '../../assets/images/HomePage/05.jpg'
import { useTranslation } from 'react-i18next'


const Intro = () => {

    const { t } = useTranslation();
    return (
        <div className='container lg:px-[20px] px-4 lg:mt-4 mt-4 md:mt-0 2xl:p-0 mx-auto'>
            <div className='grid lg:grid-cols-2 grid-cols-1 gap-8 md:px-4 lg:px-8 pt-4 md:py-11'>

                <div className='md:pt-11 2xl:pt-16'>
                    <h1 className='font-extrabold md:text-3xl text-2xl mb-4 text-[#132b38]'>{t('home.descriptionTitle')}</h1>
                    <hr className='border-[2px] border-[#07c9e1] w-[80px]' />

                    <p className='pt-8 text-[#666666] text-justify'>{t('home.description')}

                        <Link to='/About' className='italic text-black hover:text-[#07c9e1]'>
                            {t('home.LinkButton')}</Link>
                    </p>

                </div>
                <div className='md:mb-0 mb-4 lg:mt-16 lg:pt-0 pt-4'>
                    <img src={logistic} alt='logistic' className='rounded w-[100%] ' />
                </div>
            </div>
        </div>
    )
}

export default Intro
