import React from 'react'
import { useParams } from 'react-router-dom';
import MainLayout from '../../layouts/MainLayout'
import RegionDetails from '../../componenets/Region/RegionDetails'

const Region = () => {
    const { location } = useParams();
    return (
        <MainLayout>
            <RegionDetails location={location} />
        </MainLayout>
    )
}

export default Region
