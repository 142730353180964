import React, { lazy } from 'react'
import MainLayout from '../../layouts/MainLayout'

const Slider = lazy(() => import('../../componenets/Services/national/Slider'));
const ExplainSection = lazy(() => import('../../componenets/Services/national/ExplainSection'));
const Advantages = lazy(() => import('../../componenets/Services/national/Advantages'));
const LogisticsSolutions = lazy(() => import('../../componenets/Services/LogisticsSolutions'));
const Process = lazy(() => import('../../componenets/Services/national/Process'));
const Enquiry = lazy(() => import('../../componenets/Services/Enquiry'));


const National = () => {
    return (
        <MainLayout>
            <Slider />
            <ExplainSection />
            <Advantages />
            <LogisticsSolutions />
            <Process />
            <Enquiry />
        </MainLayout>
    )
}

export default National
