import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

const ServicesContent = () => {
    const { t } = useTranslation();

    return (
        <div className="w-[120px] bg-white shadow-2xl rounded-xl">
            <div className="">
                <NavLink to='/National'>
                    <div className="block px-3 py-3 text-sm hover:rounded-t hover:bg-[#07c9e1] hover:text-white text-left font-bold border-b-[1px]">
                        {t('navbar.national')}
                    </div>
                </NavLink>
                <NavLink to='/Prices'>
                    <div className="block px-3 py-3 text-sm hover:rounded-t hover:bg-[#07c9e1] hover:text-white text-left font-bold border-b-[1px]">
                        {t('navbar.prices')}
                    </div>
                </NavLink>

                <NavLink to='/International'>
                    <div className="block text-sm px-3 py-3 hover:rounded-b hover:bg-[#07c9e1] hover:text-white font-bold text-left">
                        {t('navbar.international')}
                    </div>
                </NavLink>
            </div>
        </div>
    )
}

export default ServicesContent
