import { useState } from 'react'

const UseForm = () => {
    const [formData, setFormData] = useState(
        {
            name: '',
            email: '',
            phone: '',
            message: '',
        }
    );

    const handleSubmit = (callback) => (e) => {
        e.preventDefault();
        try {
            callback(formData);
            setFormData(
                {
                    name: '',
                    email: '',
                    phone: '',
                    message: '',

                }
            )
        } catch (err) {
            console.log(err)
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    }

    return { formData, handleSubmit, handleChange }
}

export default UseForm
