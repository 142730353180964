import React from 'react'
import { urlFor } from '../../utils/sanityUtils'

const RegionDescription = ({ region }) => {
    return (
        <div className='container lg:px-[20px] px-4 2xl:p-0 my-8 mx-auto'>
            <div className='flex items-center justify-center flex-col mb-8'>
                <h1 className='font-extrabold text-center md:text-3xl text-2xl mb-4'>Présentation de la région</h1>
                <hr className='border-[2px] border-[#07c9e1] w-[80px]' />
            </div>
            <div className='lg:p-8 md:p-4 mt-4'>
                <img className='float-right md:w-[50%] w-[100%] lg:w-[40%] h-[300px] mb-4 md:mb-0 md:ml-3' src={urlFor(region.mapImg).url()} alt={region.region} />
                {region.description.map((text, idx) => (
                    <p key={idx} className='pb-4 text-[#666666] text-justify'>{text.paragraph}</p>
                ))}

            </div>
        </div>
    )
}

export default RegionDescription
