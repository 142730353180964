import React from 'react'
import colis from '../../../assets/images/articles/Trends/courrier-sans-visage-colis-pres-table.jpg'

const GrowthEcom = () => {
    return (
        <div className='container lg:px-[20px] px-4 2xl:p-0 mx-auto'>
            <div className='flex items-center justify-center flex-col py-4'>
                <h1 className='font-extrabold lg:text-3xl text-center text-2xl text-[#132b38]'>Growth in ecommerce means growth in returns</h1>
            </div>

            <div className='lg:p-8 md:p-4 pb-0 pt-4 text-[#666666] text-justify'>
                <p className='mb-4'>A logical consequence of increasing ecommerce is an increase in returned goods. That's an issue for B2B transactions just as much as it is for B2C.</p>

                <p className='mb-4'>
                    It's having an impact on warehousing in the US, where the need for reverse logistics is the main factor when it comes to leased warehouse space. According to commercial property experts CBRE, the need to handle returned goods accounts for 65 million square meters of warehouse space in the US alone.
                </p>

                <p className='mb-4'>
                    The increasing rate of returns represents a financial and logistical concern for businesses which could see huge - and valuable - volumes of inventory flowing out and then back in again.
                </p>

                <p className=''>
                    There is an opportunity, however, for retailers to gain a competitive advantage through a smooth returns process. If someone's experience is positive, it's likely that they will buy from that retailer again. Conversely, a bad returns experience lowers the chance of a repeat purchase.
                </p>
            </div>

            <div className='flex items-center justify-center flex-col lg:p-8 md:p-4 py-0'>
                <img src={colis} alt='colis' className='py-8 w-[70%]' />
            </div>
        </div>
    )
}

export default GrowthEcom
