import React, { useState } from 'react'
import { AnimatePresence, motion } from "framer-motion";
import { Link } from 'react-router-dom'

const FlyoutLink = ({ children, href, Content }) => {
    const [open, setOpen] = useState(false);
    const showFlyout = Content && open;

    return (
        <div
            onMouseEnter={() => setOpen(true)}
            onMouseLeave={() => setOpen(false)}
            className="relative w-fit h-fit"
        >
            <Link to={href} className="relative">
                {children}
                <span
                    style={{
                        transform: showFlyout ? "scaleX(1)" : "scaleX(0)",
                    }}
                    className="absolute -top-2 -left-1 -right-1 h-1 origin-left scale-x-0 rounded-full bg-[#07c9e1] transition-transform duration-300 ease-out"
                />
            </Link>
            <AnimatePresence>
                {showFlyout && (
                    <motion.div
                        initial={{ opacity: 0, y: 15 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: 15 }}
                        style={{ translateX: "-30%" }}
                        transition={{ duration: 0.3, ease: "easeOut" }}
                        className="absolute left-1/2 top-8 rounded bg-white text-black"
                    >
                        <div className="absolute -top-6 left-0 right-0 h-6 bg-transparent" />
                        <Content />
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default FlyoutLink
