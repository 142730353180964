import React from 'react'
import MainLayout from '../../layouts/MainLayout';
const NotFound = () => {
    return (
        <MainLayout>
            <section className="container lg:px-[20px] px-4 2xl:p-0 2xl:mt-20 mt-16 py-14 2xl:py-24 mx-auto">
                <div className="">
                    <div className="mx-auto max-w-screen-sm text-center">
                        <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-primary-600">404</h1>
                        <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl">Something's missing.</p>
                        <p className="mb-4 text-lg font-light text-gray-500">Sorry, we can't find that page. You'll find lots to explore on the home page. </p>

                    </div>
                </div>
            </section>
        </MainLayout>
    )
}

export default NotFound
