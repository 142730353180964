import React from 'react'
import sliderImg from '../../../assets/images/articles/horizonEcommerce/bg_slid.webp'
const Slider = () => {
    return (
        <div className='lg:h-[480px] 2xl:h-[590px] h-[400px] bg-cover bg-center relative' style={{ backgroundImage: `url(${sliderImg})` }}>
            <div className='container lg:px-[20px] px-4 2xl:p-0 mx-auto flex flex-wrap'>
                <div className='w-[55%] hidden lg:block'></div>

                <div className='lg:w-[45%] md:w-[80%] flex flex-col text-white lg:p-8 md:px-4 lg:pt-[15%] md:pt-[20%] pt-[35%]'>
                    <h1 className='font-extrabold lg:text-3xl md:text-2xl text-[20px]'>A boom with a view - what's on the horizon for E-commerce?</h1>
                    <p className='md:pt-8 pt-8 text-sm 2xl:text-base font-bold text-justify'>Online retail and parcel volumes surged spectacularly during the pandemic, but growth has normalized post-Covid. So, what's in store for e-commerce now? Let's explore the key trends.</p>
                </div>
            </div>
        </div>
    )
}

export default Slider
