import React, { useEffect, useState, useMemo } from 'react';
import createClient from '../../client';
import RegionSlide from './RegionSlide';
import RegionDescription from './RegionDescription';
import Provinces from './Provinces';

const RegionDetails = ({ location }) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        createClient.fetch(`*[_type == "RegionsOfMoroccoMap"]`)
            .then(data => setData(data))
            .catch(err => console.log(err));
    }, []);

    // Memoize the filtered regions based on the location prop
    const filteredRegions = useMemo(() => {
        return data.flatMap(item => (
            item.regions.filter(region => region.region === location)
        ));
    }, [data, location]);

    return (
        <div>
            {filteredRegions.map((region, index) => (
                <React.Fragment key={index}>
                    <RegionSlide title={region.title} />
                    <RegionDescription region={region} key={index} />
                    <Provinces region={region} />
                </React.Fragment>
            ))}
        </div>
    );
};

export default RegionDetails;
