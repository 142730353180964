import React from 'react'
import sliderImg from '../../../assets/images/articles/delivery/delivery.webp'

const Slider = () => {
    return (
        <div className='h-[350px] lg:h-[450px] bg-cover bg-center relative' style={{ backgroundImage: `url(${sliderImg})` }}>
            <div className='container lg:px-[20px] px-4 2xl:p-0 mx-auto'>
                <div className='w-[55%] hidden lg:block'></div>

                <div className='lg:w-[45%] md:w-[80%] flex flex-col text-white lg:p-8 md:px-4 pt-20 lg:pt-[10%] md:pt-[15%]'>
                    <h1 className='font-extrabold lg:text-3xl md:text-2xl text-[20px]'>Quick Commerce: getting on-demand delivery logistics right</h1>

                    <p className='md:pt-11 lg:pt-8 pt-6 text-sm font-bold text-justify'>Blink, and you'll miss out: Quick commerce is quickly emerging as the next big trend in e-commerce. It's changing how goods are ordered, warehoused, and delivered - with profound implications for e-commerce business owners. Success depends on getting the logistics just right.</p>
                </div>
            </div>
        </div>
    )
}

export default Slider
