import React from 'react'
import postmatsImg from '../../../assets/images/articles/locker/pastomatas.webp'
const Description = () => {
    return (
        <div className='container lg:px-[20px] px-4 2xl:p-0 mx-auto'>
            <div className='flex items-center justify-center flex-col pt-11 pb-4'>
                <h1 className='font-extrabold lg:text-3xl text-center text-2xl text-[#132b38]'>What Are Parcel Lockers?</h1>
            </div>
            <div className='lg:p-8 md:p-4 text-[#666666] text-justify'>
                <img src={postmatsImg} alt='postmatsImg' className='rounded md:ml-3 md:w-[50%] w-[100%] md:h-[300px] lg:h-[350px] md:object-cover my-4 md:my-0 float-right' />

                <p className='mb-4 leading-loose 2xl:pt-8'>Parcel smart lockers are secure, self-service storage units designed to receive, store, and dispense packages and mail, usually located in centralized and accessible locations. These lockers come in various sizes and configurations, from small cubicles for envelopes to larger compartments for bulkier boxes.</p>

                <p className='mb-4 leading-loose'>Recipients will be promptly notified via email or SMS when their package is delivered, informing them which assigned compartment they have, and will receive the OTP they require to access their mail. There's other configured solution for various means of possible access such as through QR codes, employee badges, or other encrypted means.</p>            </div>
        </div>
    )
}

export default Description
