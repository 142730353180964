import React from 'react'

const ExplainSection = () => {
    return (
        <div className='container lg:px-[20px] px-4 2xl:p-0 mx-auto'>
            <div className='flex items-center justify-center flex-col py-4 md:pt-0'>
                <h1 className='font-extrabold lg:text-3xl text-center text-2xl text-[#132b38]'>Weigh and save</h1>
            </div>

            <div className='lg:p-8 md:p-4 text-[#666666] text-justify'>
                <p>To save on shipping and avoid paying to transport air, online merchants should consider the following:</p>
                <ul className='list-disc md:pl-8 pl-4'>
                    <li className='pl-4 pt-4'>Shipping companies use a dimensional weight (DIM weight) pricing technique based on the package's length, width, and height.</li>

                    <li className='pl-4'>DIM weight is a standard pricing method used by most shipping providers.</li>
                    <li className='pl-4'>If an item is lightweight, but you are using a large box, you will still pay more because of the volume and the space the box will take up during transport.</li>
                </ul>
            </div>

            <div className='lg:p-8 md:p-4 py-4 text-[#666666] text-justify'>
                <p>What should an ecommerce merchant do?</p>
                <ul className='list-disc pl-8'>
                    <li className='pl-4 pt-4'>Consider packaging at the onset of launching a new product or product line. Some packaging manufacturers can create custom packages that decrease dimensional weight, thus reduce costs and carbon footprint.</li>

                    <li className='pl-4'>Weigh packages and consider dimensions when calculating shipping costs.</li>
                    <li className='pl-4'>Talk to your logistics operators to see what options are at your disposal to make your packaging more efficient.</li>
                </ul>
            </div>
        </div>
    )
}

export default ExplainSection
