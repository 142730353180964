import React from 'react'
import { useTranslation } from 'react-i18next'
import FlyoutLink from './Drodawn/FlyoutLink';
import ServicesContent from './Drodawn/ServicesContent';


const ServicesLgScreen = () => {
    const { t } = useTranslation();
    return (
        <div className="cursor-pointer hover:text-[#1374b4] mr-3 font-bold px-3">
            <FlyoutLink Content={ServicesContent}>
                {t('navbar.services')}
            </FlyoutLink>
        </div>
    )
}

export default ServicesLgScreen
