import React from 'react'

const Benifits = () => {
    const data = [
        {
            title: "Convenience",
            description: "Parcel smart lockers provide 24/7 access, allowing recipients to pick up their packages on their schedule, eliminating the possibility of failed delivery attempts when recipients happen to be out of home during shipment. Not only is this a compelling benefit for the user, but this reduces the delivery attempts made, thus lowering delivery costs for the service provider."
        },
        {
            title: "Security",
            description: "Parcel smart lockers offer a high level of security through the robust material selected for the body and frame of the lockers. On top of this, we offer the ability to install a security camera in order to further deter theft and vandalism. Lastly, we see lockers and their secured storage for parcels as an amazing solution and alternative for packages which go missing due to theft when unattended at reception desks, lobbies, or the front doors of apartments or houses."
        },
        {
            title: "Temperature-controlled lockers",
            description: "By providing the ability to heat locker compartments, we can effectively improve the quality of food delivery by maintaining its original temperature."
        },
        {
            title: "Efficiency",
            description: "Smart lockers can streamline the package handling delivery process by eliminating the need for any receptionist or employee to sort through various packages which will eventually be handed to a tenant. Additionally, the lockers are designed for self-service, recipients can swiftly obtain their packages without waiting in any lines at front desks or post offices."
        }
    ]
    return (
        <div className='container lg:px-[20px] px-4 2xl:p-0 mx-auto'>
            <div className='flex items-start flex-col lg:p-8 md:p-4 pt-4 pb-0'>
                <h1 className='font-extrabold lg:text-3xl text-center text-2xl text-[#132b38]'>Benefits of Parcel Smart Lockers</h1>
            </div>

            <div className='lg:p-8 md:p-4 py-4'>
                <ul className='lg:pl-8 pl-4'>
                    {
                        data.map((item, index) => (
                            <li key={index} className='list-decimal'>
                                <h5 className='font-bold text-[#1374b4] pt-4'>{item.title}:</h5>
                                <p className='text-[#666666] text-justify'>{item.description}</p>
                            </li>
                        ))
                    }
                </ul>
            </div>
        </div>
    )
}

export default Benifits
