import React from 'react'
import sliderImg from '../../assets/images/slider/newsSlider.jpg'

const TermsSlide = () => {
    return (
        <div className='md:h-[300px] h-[250px] bg-cover bg-center relative' style={{ backgroundImage: `url(${sliderImg}` }}>
            <div className='container flex flex-col justify-center items-center h-[100%] mx-auto'>
                <h1 className='lg:text-4xl md:text-3xl text-2xl text-white text-center font-bold'>Conditions générales de vente (CGV)</h1>
            </div>
        </div>
    )
}

export default TermsSlide
