import React from 'react'
import { Link } from 'react-router-dom'
import wrapping from "../../../assets/images/articles/Packging/person-wrapping-christmas-gift.jpg"

const Description = () => {
    return (
        <div className='container lg:px-[20px] px-4 flex flex-wrap 2xl:px-0 mx-auto'>
            <div className='flex items-center justify-center flex-col pt-11 w-[100%] pb-4'>
                <h1 className='font-extrabold lg:text-3xl text-center text-2xl text-[#132b38]'>Shipping air can be costly to your online business and reputation</h1>
            </div>

            <div className='lg:p-8 md:p-4 text-[#666666] text-justify'>

                <img src={wrapping} alt='wrapping' className='rounded md:ml-3 h-[260px] lg:h-[300px] md:w-[50%] w-[100%] my-4 md:my-0 md:pl-4 float-right' />

                <p className='mb-4'>Have you ever had an item shipped to your home, and when you open it up, it's like opening a Russian nesting doll? You find a box inside of a box, inside of a box filled with void-fill material.</p>

                <p className='mb-4'>This first impression sets the stage for what online consumers associate with your brand. Is it an environmentally friendly company that cares about using less packaging or biodegradable materials?</p>

                <p className='mb-4'>The International Post Corporation's <Link to="https://www.ipc.be/services/markets-and-regulations/cross-border-shopper-survey" target='_blank' className='text-[#07c9e1] underline'>Cross-Border Ecommerce Shopper Survey 2020</Link> revealed that 48 percent of global consumers strongly agreed that they would like the packaging of their parcels to be recyclable and expressed a strong preference to receive parcels in reusable materials.</p>



            </div>

            <div className='lg:px-8 md:px-4 py-0 text-[#666666] text-justify'>
                <p className='mb-4'>
                    For online merchants, packaging can be a way to position their brand as eco-friendly and sustainable, and an effective way to save on costs. For some companies, it is becoming an important topic for their business. In a survey conducted by IRSALKOM's Customer Solutions & Innovation team, it was found that nine out of 10 companies say packaging will be high on their agenda in the next three to five years.
                </p>

                <p className='mb-4'>
                    The IRSALKOM survey also revealed that the majority of respondents said the introduction of sustainable packaging materials was their number one near-future packaging priority.
                </p>
            </div>
        </div>
    )
}

export default Description
