import React from 'react'

const Content = () => {
    return (
        <div className='container lg:px-[20px] px-4 2xl:p-0 mx-auto'>
            <div className='flex items-center justify-center flex-col lg:p-8 md:p-4 my-4 lg:my-0 py-0'>
                <h1 className='font-extrabold lg:text-3xl text-center text-2xl text-[#132b38]'>Benefits of 3PL for SMB logistics</h1>
            </div>
            <div className='lg:p-8 md:p-4 text-[#666666] text-justify'>
                <p className='py-4 lg:pt-0 mb-4'>3PL providers typically focus on order fulfillment - storing inventory, picking and packing orders, and shipping packages - and cater to small and medium-sized businesses (SMBs) seeking rapid, seamless, sustainable growth. SMBs often lack the necessary economies of scale to justify acquiring their own fulfillment infrastructure. A good 3PL comes to the rescue with not only “hard” infrastructure, such as warehouses and transport vehicles, but also “soft” infrastructure, such as expertise and technology.</p>

                <p className='py-4 lg:pt-0 mb-4'>3PL technology can include warehouse management systems, data analytics and demand forecasting tools, and digital dashboards and control tower solutions that let customers track the location of their goods in real time. It's the kind of smart tech increasingly common among logistics specialists but simply not accessible or scalable for SMBs whose core mission and expertise lie elsewhere. Such solutions are critical to real-time visibility and eliminating time-consuming WISMO (where is my order?) inquiries.</p>
            </div>



            <div className='flex items-center justify-center flex-col lg:p-8 md:p-4 my-4 lg:my-0 py-0'>
                <h1 className='font-extrabold lg:text-3xl text-center text-2xl text-[#132b38]'>Benefits of 4PL for enterprise logistics</h1>
            </div>
            <div className='lg:p-8 md:p-4 text-[#666666] text-justify'>
                <p className='py-4 lg:pt-0 mb-4'>As a business grows and thrives, the number of 3PL players critical to its success also rises. This makes communication and coordination increasingly burdensome and can even lead to inconsistent customer outcomes depending on which 3PL provider fulfills a given order. This is when medium-sized and larger companies typically turn to a 4PL provider.</p>

                <p className='py-4 lg:pt-0 mb-4'>If you think of 3PL as the logistics arm of an SMB, then 4PL is the logistics brain of a large enterprise. 4PL providers are end-to-end strategists managing and controlling the myriad activities within a supply chain network. A good 4PL offers a single interface for data consolidated from multiple 3PLs and a single point of contact for communications throughout the supply chain. Industry leaders use a sophisticated control tower solution offering the appropriate visibility to each supply chain player, from suppliers and manufacturers to shippers and end customers. This can give larger enterprises the competitive advantage to meet challenging customer needs, such as repeatedly delivering on-time and in-full.</p>
            </div>


            <div className='flex items-center justify-center flex-col lg:p-8 md:p-4 my-4 lg:my-0 py-0'>
                <h1 className='font-extrabold lg:text-3xl text-center text-2xl text-[#132b38]'>Think 3PL and 4PL, not 3PL vs. 4PL</h1>
            </div>
            <div className='lg:p-8 md:p-4 text-[#666666] text-justify'>
                <p className='py-4 lg:pt-0 mb-4'>Most people ask us what the difference is between 3PL and 4PL, as if the two services compete with each other, but a 3PL  versus  4PL mindset is misguided. Both  3PL  and  4PL can be the right solution for a business in different phases of its growth trajectory from plucky startup to market giant. And a 4PL model incorporates 3PLs, of course.</p>

                <p className='py-4 lg:pt-0 mb-4'>Both 3PLs and 4PLs are businesses that live and breathe logistics - experts in an increasingly complex and vital domain. This gives them the savvy to anticipate potentially catastrophic disruptions and the ability to navigate around them.</p>
            </div>
        </div>
    )
}

export default Content
