import React from 'react'
import MainLayout from '../../../layouts/MainLayout'
import Slider from '../../../componenets/Articles/HorizonEcommerce/Slider'
import Description from '../../../componenets/Articles/HorizonEcommerce/Description'
import Details from '../../../componenets/Articles/HorizonEcommerce/Details'
import Growth from '../../../componenets/Articles/HorizonEcommerce/Growth'
import ExplainSection from '../../../componenets/Articles/HorizonEcommerce/ExplainSection'
import Content from '../../../componenets/Articles/HorizonEcommerce/Content'

const HorizonEcommerce = () => {
    return (
        <MainLayout>
            <Slider />
            <Description />
            <Details />
            <Growth />
            <ExplainSection />
            <Content />
        </MainLayout>
    )
}

export default HorizonEcommerce
