import React from 'react'
import methodU from '../../../assets/images/shippingSupport/methodU.jpg'
import methodL from '../../../assets/images/shippingSupport/methodL.png'
import methodH from '../../../assets/images/shippingSupport/methodH.jpg'
import colageImg from '../../../assets/images/shippingSupport/1048_gc.jpg'
import etap4 from '../../../assets/images/shippingSupport/pochette-cartonnee.jpg'



const Steps = () => {
    return (
        <div className='container lg:px-[20px] h-auto px-4 2xl:p-0 md:my-14 my-8 mx-auto'>
            <div className='lg:px-8 md:px-4 lg:pt-4'>
                <h3 className='text-[#242a65] font-bold'>Etape 1 : Choisissez le type d'emballage adéquat</h3>
            </div>
            <div className='text-[#666666] lg:px-8 md:px-4'>
                <p className='my-4 md:text-justify'>Avant de choisir l'emballage de votre colis, il est important de savoir que ceux-ci sont manutentionnés sur des chaînes automatisées et parfois, il peut y avoir des chutes. </p>

                <p className='mb-4 md:text-justify'>Pour protéger convenablement un colis, il faut imaginer que celui-ci puisse être protégé et résister à une chute d'environ <strong className='text-black'>1m30 de hauteur</strong>. Partant de ce principe, vous pouvez estimer le degré de fragilité de votre marchandise.</p>

                <p className='mb-4 md:text-justify'>Pour choisir les bons emballages pour vos colis, il faut tout d'abord prendre en compte : <strong className='text-black'>la forme, le poids, la taille et la forme</strong> de votre marchandise. Selon la fragilité de votre produit, privilégiez un carton simple, double ou triple cannelure pour une protection optimale. Pour les objets de plus de <strong className='text-black'>50 kilos</strong>, optez pour la boîte en bois, en métal ou en plastique.</p>


                <p className='my-4 md:text-justify'>Pour être sûr de ne rencontrer aucun problème, privilégiez les emballages de forme <strong className='text-black'>“classique”</strong>. Par exemple, si vous expédiez une guitare, privilégiez un emballage de forme rectangulaire adapté à la taille de votre guitare. <strong className='text-black'>Évitez les formes atypiques de type incurvées, coniques ou cylindriques,</strong> car votre colis sera disposé sur une surface plane. Certains transporteurs refusent également tout colis qui n'aurait pas une forme classique. Lors de la livraison, les transporteurs utilisent un acheminement industriel, peu adapté pour des colis aux formes irrégulières.</p>

                <p className='my-4'>Veillez à adapter la taille de votre emballage à la taille de votre produit pour éviter trop de flottement.</p>
            </div>


            <div className='lg:p-8 md:p-4 md:pt-4'>
                <h3 className='text-[#242a65] font-bold'>Etape 2 : calez et disposez vos produits </h3>
            </div>
            <div className='text-[#666666] lg:px-8 md:px-4'>
                <div>
                    <p className='my-4 md:text-justify'>Utilisez des matériaux comme le papier bulle (idéal pour les produits fragiles), le papier kraft, le polystyrène, les coussins d'air comprimé ou encore des journaux.  <strong className='text-black'>N'hésitez pas à bien protéger vos objets fragiles </strong> et à rembourrer l'intérieur de votre carton pour éviter les vides ou flottements.</p>

                    <p className='mb-4 md:text-justify'>Si vous avez plusieurs objets dans un même carton, placez le plus lourd en bas du carton et répartissez le reste dans votre colis. Veillez également à ne pas mettre deux objets fragiles en contact dans le même emballage.</p>
                </div>

                <div>
                    <p className='mb-4 md:text-justify'>Voici une liste non exhaustive de matériaux de calage adapté au contenu de vos colis :</p>
                    <div className='flex items-center justify-center mb-4'>
                        <img src={colageImg} alt='colage' className='rounded md:w-[50%]' />
                    </div>

                    <ul className='pl-8 font-bold text-black list-disc'>
                        <li className='py-4 pl-4'>papier bulle</li>
                        <li className='pb-4 pl-4'>papier kraft</li>
                        <li className='pb-4 pl-4'>polystyrène</li>
                        <li className='pb-4 pl-4'>journaux</li>
                        <li className='pb-4 pl-4'>coussins d'air comprimé</li>
                        <li className='pb-4 pl-4'>fibre de calage (Raja)</li>
                        <li className='pb-4 pl-4'>frisure de Calage</li>
                        <li className='pb-4 pl-4'>film mousse</li>
                        <li className='pb-4 pl-4'>filet de palettisation</li>
                        <li className='pb-4 pl-4'>particules de calage</li>
                        <li className='pb-4 pl-4'>carton à cannelure</li>
                    </ul>



                </div>
            </div>

            <div className='lg:p-8 md:p-4 md:pt-4'>
                <h3 className='text-[#242a65] font-bold'>Etape 3: fermez correctement l'emballage</h3>
            </div>
            <div className='text-[#666666] lg:px-8 md:px-4'>

                <p className='my-4 md:text-justify'>Un doute sur le placement du ruban adhésif ? Il existe plusieurs façons de sceller votre colis: </p>

                <p className='mb-4 md:text-justify'><strong className='text-black'>La méthode en U: </strong> ce mode de fermeture est adapté au colis légers, il consiste à sceller le haut et le bas de votre carton sur toute sa longueur en laissant déborder votre adhésif sur chaque côté. </p>

                <div className='flex items-center justify-center'>
                    <img src={methodU} alt='methodU' className='rounded' />
                </div>

                <p className='my-4 md:text-justify'><strong className='text-black'>La méthode en L :</strong> cette fermeture est adaptée aux envois légers, et consiste en scotcher votre colis sur la longueur en laissant dépasser une partie de l'adhésif sur la largeur.</p>

                <div className='flex items-center justify-center'>
                    <img src={methodL} alt='methodL' className='rounded' />
                </div>

                <p className='my-4 md:text-justify'><strong className='text-black'>La méthode en H :</strong> ce mode de fermeture est adapté à tout type de colis, fragile ou non. Placez le ruban sur toute la longueur du carton en haut et en bas, ainsi qu'aux 4 extrémités. Choisissez un ruban adhésif de 5 cm minimum. </p>

                <div className='flex items-center justify-center'>
                    <img src={methodH} alt='methodH' className='rounded' />
                </div>

                <p className='text-black my-4'>N'utilisez pas de ficelle ou d'emballage à poignée ou rabat, certains transporteurs pourraient refuser votre colis.</p>
            </div>


            <div className='lg:p-8 md:p-4 md:pt-4'>
                <h3 className='text-[#242a65] font-bold'>Étape 4 :  Préparez-vous pour l'expédition</h3>
            </div>
            <div className='text-[#666666] lg:px-8 md:px-4'>
                <p className='my-4 md:text-justify'>Maintenant que votre colis est bien emballé et que votre colis est correctement fermé, il ne vous reste plus que la touche finale! <strong className='text-black'>Imprimez votre bordereau</strong> et <strong className='text-black'>collez-le sur une surface plane</strong>, sur le haut de votre colis : l'étiquette ne doit en aucun cas être masqué ou servir à fermer votre envoi. L'adresse d'expédition, de destination et le code-barre du bordereau doivent être bien lisibles pour le transporteur et éviter tout retour ou erreur de livraison. </p>

                <div className='flex items-center justify-center my-4'>
                    <img src={etap4} alt='etap4' className='md:w-[50%] rounded' />
                </div>

                <p className='mb-4 md:text-justify'>Vous avez la possibilité de coller des étiquettes <strong className='text-black'>“fragile”</strong> ou <strong className='text-black'>“International”</strong> sur votre colis si vous souhaitez encore plus de précautions lors du transport de vos marchandises, mais cette étape n'est pas obligatoire. </p>


            </div>
        </div>
    )
}

export default Steps
