import React from 'react'
import logo from '../../assets/images/logo/LOGO_IRSALKOM-03.png'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import SocialMedia from './SocialMedia'
import { useTranslation } from 'react-i18next'

const Footer = () => {
    const { t } = useTranslation();
    const currentYear = new Date().getFullYear();

    let links = [
        { name: t('navbar.home'), path: '/' },
        { name: t('navbar.about'), path: '/About' },
        { name: t('navbar.national'), path: '/National' },
        { name: t('navbar.international'), path: '/International' },

    ];
    let guidLinks = [
        { name: t('footer.shipping_support'), path: '/shipping_support' },
        { name: t('footer.ClientArea'), path: 'https://app.irsalkom.ma/login' },
        { name: t('footer.terms'), path: '/Terms-Of-Sales' }

    ];

    return (
        <div className='bg-[#171b3e]'>
            <div className='container lg:px-[20px] px-4 py-8 mx-auto'>
                <div className='grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 lg:gap-8 md:px-8 gap-4 px-3'>

                    <div className='text-white md:pt-2 2xl:pt-0'>
                        <Link to="/">
                            <img src={logo} className='md:w-[80%] w-[65%] lg:mb-6 mb-4' alt='logo' />
                        </Link>

                        <p className='lg:pt-5 w-[70%]'>{t('footer.slogan')}</p>

                        <SocialMedia />

                    </div>
                    <div className='pt-8 text-white'>
                        <h1 className='lg:mb-8 mb-4 text-[#07c9e1] tracking-wide text-[20px] font-bold'>{t('footer.QuickLink')}</h1>
                        <ul>
                            {
                                guidLinks.map((link, index) => (
                                    <li className='pt-8' key={index}>
                                        <Link to={link.path} className="cursor-pointer hover:text-[#07c9e1]">{link.name}</Link>
                                    </li>
                                ))
                            }

                        </ul>
                    </div>
                    <div className='pt-8 text-white'>
                        <h1 className='lg:mb-8 mb-4 text-[#07c9e1] tracking-wide text-[20px] font-bold'>{t('footer.navlinks')}</h1>
                        <ul className='flex item-center flex-col justify-between'>
                            {
                                links.map((link, index) => (
                                    <li className='pt-8' key={index}>
                                        <Link to={link.path} className="cursor-pointer hover:text-[#07c9e1]">{link.name}</Link>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                    <div className='pt-8 text-white'>
                        <h1 className='lg:mb-8 mb-4 text-[#07c9e1] tracking-wide font-bold text-[20px]'>{t('navbar.contact')}</h1>
                        <ul className='list-none'>
                            <li className='flex pt-8'>
                                <FontAwesomeIcon icon={faWhatsapp} className="w-6 h-6 mr-3" />
                                <span>+212 7 09 04 00 00</span>
                            </li>
                            <li className='flex pt-8'>
                                <FontAwesomeIcon icon={faPhone} className="w-6 h-6 mr-3" />
                                <div className='flex flex-col'>
                                    <span>+212 5 28 23 26 27</span>
                                </div>


                            </li>
                            <li className='flex pt-8'>
                                <FontAwesomeIcon icon={faPhone} className="w-6 h-6 mr-3" />
                                <div className='flex flex-col'>
                                    <span>+212 5 22 71 80 81</span>
                                </div>

                            </li>
                            <li className='flex pt-8'>
                                <FontAwesomeIcon icon={faEnvelope} className="w-6 h-6 mr-3" />
                                <span>contact@irsalkom.ma</span>
                            </li>
                        </ul>
                    </div>

                </div>
                <div className=' text-center pt-14'>
                    <p className='text-white'>Copyright &copy; {currentYear} - All rights reserved.</p>
                </div>
            </div>

        </div>
    )
}

export default Footer
