import React from 'react'
import sliderImg from "../../../assets/images/articles/Packging/package.webp"

const Slider = () => {
    return (
        <div>
            <div className='md:h-[450px] 2xl:h-[550px] h-[350px] bg-cover bg-center relative' style={{ backgroundImage: `url(${sliderImg})` }}>
                <div className='container lg:px-[20px] px-4 flex flex-wrap 2xl:px-0 mx-auto'>

                    <div className='lg:w-[45%] md:w-[80%] flex flex-col text-white lg:p-8 md:pt-[20%] lg:pt-[10%] pt-[30%]'>
                        <h1 className='font-extrabold lg:text-3xl md:text-2xl text-[20px]'>Packaging can propel an e-commerce brand's reputation</h1>

                        <p className='md:pt-11 pt-6 text-sm font-bold text-justify'>When starting an online store, ecommerce entrepreneurs have so much to consider, including how they will drive more sales, setting up shipping, among other topics. For many, packaging is towards the middle or end of their list of things to prioritize, but efficient packaging can be a cost saver and set you apart in the market.</p>
                    </div>

                    <div className='w-[55%] hidden lg:block'></div>
                </div>
            </div>
        </div>
    )
}

export default Slider
