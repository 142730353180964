import React from 'react'
import MainLayout from '../../layouts/MainLayout'
import EcomSlider from '../../componenets/Blog/BlogSlider'
import Section from '../../componenets/Blog/Section'


const Blog = () => {
    return (
        <MainLayout>
            <EcomSlider />
            <Section />
        </MainLayout>
    )
}

export default Blog
