import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FaSortDown, FaSortUp } from "react-icons/fa";

const ServicesSmallScreen = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useTranslation();

    return (
        <div className="relative inline-block text-left">
            <div>
                <button
                    className="flex w-full justify-start py-2 font-bold px-3 cursor-pointer hover:text-[#1374b4]"
                    onClick={() => setIsOpen(!isOpen)}
                >
                    Services
                    {
                        isOpen ? <FaSortDown className="mt-0 text-[20px]" /> : <FaSortUp className=" mt-2 text-[20px]" />
                    }

                </button>
            </div>

            {
                isOpen && <div className="" >
                    <div className="py-1" role="none">
                        <NavLink to='/National' className="block hover:text-[#07c9e1] font-bold px-4 py-2 text-sm">
                            National
                        </NavLink>
                        <NavLink to='/Prices' className="block hover:text-[#07c9e1] font-bold px-4 py-2 text-sm">
                            {t('navbar.prices')}
                        </NavLink>
                        <NavLink to='/International' className=" block hover:text-[#07c9e1] font-bold px-4 py-2 text-sm">
                            International
                        </NavLink>
                    </div>
                </div>
            }
        </div>
    );
}

export default ServicesSmallScreen
