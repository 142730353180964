import React from 'react'
import sliderImg from '../../assets/images/slider/helpSlider.jpg'
import { useTranslation } from 'react-i18next'

const Slider = () => {
    const { t } = useTranslation();
    return (
        <div className='lg:h-[400px] 2xl:h-[450px] h-[300px] bg-cover bg-center' style={{ backgroundImage: `url(${sliderImg})` }}>
            <div className='container flex flex-col justify-center items-center h-[100%] mx-auto'>
                <h1 className='md:text-[40px] text-[30px] pt-8 md:pt-0 font-extrabold text-[#07c9e1]'>{t('ShippingSupport.sliderHeading')}</h1>
                <p className='text-white text-center mt-2'>{t('ShippingSupport.sliderSubHeading')}</p>
            </div>
        </div>
    )
}

export default Slider
