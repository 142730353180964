import React from 'react'
import classesImg from '../../../assets/images/shippingSupport/classification-marchandises-dangereuses.png'

const ListDangerous = () => {
    const data = [
        { title: "1. Matières et objets explosibles", description: "par ex. feux d'artifices, fumigènes, airbags ou prétensionneurs de ceinture de sécurité" },
        { title: "2.1. Gaz inflammables", description: "par ex. cartouches de gaz ou aérosols tels que les déodorants aérosols, bombes de peinture, laques pour cheveux et bombes de crème fouettée" },
        { title: "2.2. Gaz non inflammables", description: "par ex. oxygène comprimé, oxyde nitreux (gaz hilarant) ou cartouches de dioxyde de carbone comprimé pour gazéifier l'eau" },
        { title: "2.3. Gaz toxiques", description: "par ex. bombe insecticide ou difluorure d'oxygène" },
        { title: "3. Liquides inflammables", description: "par ex. solvants, peintures, vernis à ongles ou dissolvants" },
        { title: "4.1. Matières solides inflammables", description: "comme les allumettes" },
        { title: "4.2. Matières sujettes à la combustion spontanée", description: "comme le phosphore" },
        { title: "4.3. matières dégageant des gaz inflammables en présence d'eau", description: "comme le carbure de calcium" },
        { title: "5.1. Matières comburantes", description: "comme les engrais" },
        { title: "5.2. Peroxydes organiques", description: "par ex. eau oxygénée pour décoloration des cheveux, colles à deux composantes (glue) ou kits de réparation à la fibre de verre" },
        { title: "6.1. Matières toxiques", description: "par ex. pesticides (sous forme solide et liquide)" },
        { title: "6.2. Matières infectieuses", description: "par ex. analyses sanguines, échantillons de tissus, échantillons de cellules ou biopsies utilisés dans les essais médicaux" },
        { title: "7. Matières radioactives", description: "par ex. détecteurs de fumée ou isotopes médicaux" },
        { title: "8. Matières corrosives", description: "comme l'eau de Javel ou les produits de débouchage" },
        { title: "9. Matières et objets dangereux divers", description: "par ex. aimants, batteries et piles au lithium ou carboglace" }
    ]
    return (
        <div className='container lg:px-[20px] px-4 2xl:p-0 mb-8 mx-auto'>
            <h3 className='font-bold text-[#242a65] lg:px-8 md:px-4 mb-8 md:mb-0'>Quelles sont les 9 classes de marchandises dangereuses ?</h3>

            <div className='lg:p-8 md:p-4 text-[#666666]'>
                <p className='md:text-left'>Les marchandises dangereuses sont subdivisées en neuf classes, avec en plus quelques sous-classes. La classe dont relève votre produit détermine les normes d'emballage, d'étiquetage et de transport qui lui sont applicables.</p>

                <div className='flex items-center mt-4 justify-center'>
                    <img src={classesImg} alt='classes' />
                </div>

                <ul className='mt-8'>
                    {data.map((item, index) => (
                        <li key={index}>
                            <h4 className='font-bold text-[15px] md:text-[16px] text-black '>{item.title}</h4>
                            <p className='pl-8 py-4'>{item.description}</p>
                        </li>
                    ))}
                </ul>
            </div>

        </div>
    )
}

export default ListDangerous
