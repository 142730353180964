

const Support = () => {
    const data = [
        {
            title: "Qu'entend-on par marchandises dangereuses ?",
            img: "matières-danger.jpg",
            link: "marchandises-dangereuses"
        },
        {
            title: "Comment bien emballer votre colis en 4 étapes",
            img: "adhesif-emballage.webp",
            link: "Bien-emballer-colis"
        }
    ];

    return data;
}

export default Support
