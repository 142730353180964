import React from 'react'
import MainLayout from '../../../layouts/MainLayout'
import Slider from '../../../componenets/Articles/Logistics/Slider'
import Description from '../../../componenets/Articles/Logistics/Description'
import Differences from '../../../componenets/Articles/Logistics/Differences'
import Content from '../../../componenets/Articles/Logistics/Content'

const Logistics = () => {
  return (
    <MainLayout>
      <Slider />
      <Description />
      <Differences />
      <Content />
    </MainLayout>
  )
}

export default Logistics
