import React, { lazy } from 'react'
import MainLayout from '../../layouts/MainLayout'

// lazy load the components
const Slider = lazy(() => import('../../componenets/Services/International/Slider'));
const Enquiry = lazy(() => import('../../componenets/Services/Enquiry'));
const Benefits = lazy(() => import('../../componenets/Services/International/Benefits'));
const ServicesSection = lazy(() => import('../../componenets/Services/International/ServicesSection'));
const LogisticsSolutions = lazy(() => import('../../componenets/Services/LogisticsSolutions'));

const International = () => {
    return (
        <MainLayout>
            <Slider />
            <ServicesSection />
            <Benefits />
            <LogisticsSolutions />
            <Enquiry />
        </MainLayout>
    )
}

export default International
