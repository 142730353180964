import React from 'react'
import { FaDotCircle } from "react-icons/fa";

const ContentSection = ({ data }) => {

    //  Splitting date and time
    const dateTimeParts = data.date.split('T');
    //  Extracting date part
    const date = dateTimeParts[0];
    //  Extracting time part and removing milliseconds
    const time = dateTimeParts[1].split('.')[0];

    return (
        <li className='w-[100%] flex flex-wrap'>
            <div className='md:w-[50%] w-[40%] py-4 text-end md:pr-8 pr-4'>
                <p class="">{date}</p>
                <p className='text-gray-400 text-sm'>{time}</p>
            </div>
            <div className='md:w-[50%] w-[60%] border-l-2 border-dashed relative'>
                <FaDotCircle className='text-green-400 absolute top-5 left-[-10px] ' />
                <h2 class="font-bold py-4">
                    <p class="text-black md:pl-8 pl-4 text-sm ">{data.status}</p>
                </h2>
                <div class="flex flex-col justify-start items-start md:pl-14 pl-8">
                    <p class="text-gray-400 pb-4 text-sm">Location: {data.location}</p>
                </div>
            </div>
        </li>

    )
}

export default ContentSection
