import React from 'react'
import cunsomerImg from '../../../assets/images/articles/E_commerce_c2c/CONSOMER.jpg'

const BodySection = () => {
    return (
        <div className='container lg:px-[20px] px-4 2xl:p-0 mx-auto'>
            <div className='flex items-center justify-center flex-col pb-4'>
                <h1 className='font-extrabold lg:text-3xl text-center text-2xl text-[#132b38]'>Why does consumer-to-consumer work?</h1>
            </div>
            <div className='lg:p-8 md:p-4 pb-6 text-[#666666] text-justify'>

                <img src={cunsomerImg} alt='cunsomer' className='rounded h-[300px] md:ml-3 lg:h-[350px] md:w-[50%] w-[100%] my-4 md:my-0 float-right' />


                <p className='mb-4 2xl:pt-8'>The main reason is that people like to <strong className='text-black'>save money</strong> - and with recent soaring inflation around the world, that's top of mind for many buyers. "Pre-loved" items are cheaper, and most C2C e-commerce platforms charge little or no fees to buyers and sellers, which keeps prices low.</p>

                <p className='mb-4'>Both buyers and sellers also enjoy the <string className='text-black'>convenience</string> of C2C marketplaces. Sellers don't have to hassle finding potential buyers, and buyers no longer have to spend hours picking through their local secondhand shop, flea market, or neighborhood yard sale for hidden treasures. Instead, they can go online and quickly search for exactly what they're looking for, even hard-to-find items. The flexibility is also appealing. Many consumers are both buyers and sellers on the same platform.</p>


            </div>

            <div className='lg:px-8 md:px-4 pt-0 pb-6 text-[#666666] text-justify'>
                <p>Today shoppers are increasingly buying secondhand with <strong className='text-black'>sustainability</strong> in mind. A McKinsey survey found that on average 38% of shoppers in Germany, the Netherlands, and the UK buy secondhand goods to reduce waste. For example, positive fashion is a huge trend, and extending the life of clothing can reduce carbon emissions, prevent old clothes from piling up in landfills, and conserve resources, like water and energy. </p>
            </div>
        </div>
    )
}

export default BodySection
