import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import SendEmail from '../common/HandleEmail/SendEmail'
import UseForm from '../common/HandleEmail/UseForm'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const ContactSection = () => {
    const { t } = useTranslation();
    const { formData, handleChange, handleSubmit } = UseForm();

    return (
        <div className='bg-center bg-cover' >
            <div className='container lg:px-[20px] md:px-4 px-0 2xl:p-0 mx-auto'>

                <div className='grid md:grid-cols-2'>
                    <div className='w-full flex flex-col items-center text-center justify-center lg:px-[30px] px-[20px] md:py-11 py-16'>
                        <h1 className='font-extrabold lg:text-[40px] md:text-[27px] text-[24px]'>{t('ContactSection.deliveryP1')} <span className='text-[#07c9e1]'>{t('ContactSection.country')} </span>{t('ContactSection.deliveryP2')}</h1>

                        <Link to='https://app.irsalkom.ma/login' className='bg-[#07c9e1] text-white px-4 py-3 rounded md:mt-8 mt-4'>{t('ContactSection.sendBtn')}</Link>
                    </div>
                    <div className="lg:px-[50px]" >
                        <div className=' bg-[#f7f7f7] align-enter lg:px-8 p-6'>
                            <h1 className='font-bold lg:text-3xl text-2xl lg:mb-4 text-[#132b38]'>{t('ContactSection.formTitle')}</h1>
                            <hr className='border-[1.4px] lg:mb-4 border-[#07c9e1] inline-block w-[60px]' />

                            <form className='pt-2' onSubmit={handleSubmit(SendEmail)}>
                                <div className='pt-4'>
                                    <input
                                        className='md:w-[95%] w-[100%] md:mt-2 text-[#8D8D8D] p-2 outline-0'
                                        placeholder={t('ContactSection.Name')}
                                        type="text"
                                        name='name'
                                        required
                                        value={formData.name}
                                        onChange={handleChange}
                                    />
                                </div>

                                <div className='pt-4'>
                                    <input
                                        className='md:w-[95%] w-[100%] text-[#8D8D8D] md:mt-2 p-2 outline-0'
                                        placeholder={t('ContactSection.Email')}
                                        type="email"
                                        required
                                        name='email'
                                        value={formData.email}
                                        onChange={handleChange}
                                    />
                                </div>

                                <div className='pt-4'>
                                    <input
                                        className='md:w-[95%] w-[100%] text-[#8D8D8D] md:mt-2 p-2 outline-0'
                                        placeholder={t('ContactSection.Phone')}
                                        type="tel"
                                        required
                                        name='phone'
                                        value={formData.phone}
                                        onChange={handleChange}
                                    />
                                </div>

                                <div className=' pt-4'>
                                    <textarea name="message" rows="3" cols="50"
                                        className='md:w-[95%] text-[#8D8D8D] w-[100%] md:mt-2 p-2 outline-0'
                                        placeholder={t('ContactSection.Message')}
                                        value={formData.message}
                                        onChange={handleChange}
                                    ></textarea>
                                </div>

                                <div className='flex justify-start my-4'>
                                    <input type='submit' className='bg-[#07c9e1] text-white px-4 py-3 rounded cursor-pointer' value={t('ContactSection.btn')} />
                                </div>

                            </form>
                        </div>
                    </div>



                    <ToastContainer />
                </div>

            </div>

        </div>
    )
}

export default ContactSection
