import React from 'react'
import { Link } from 'react-router-dom'
import greenPac from '../../../assets/images/articles/Packging/EVBAt6NWkAYDevV.jpg'

const LastSection = () => {
    return (
        <div className='container lg:px-[20px] px-4 2xl:p-0 2xl:my-11 mx-auto'>
            <div className='flex items-center justify-center flex-col py-4 md:pt-0'>
                <h1 className='font-extrabold lg:text-3xl text-center text-2xl text-[#132b38]'>Go green packages</h1>
            </div>

            <div className='lg:p-8 md:p-4 pb-6 text-[#666666] text-justify'>

                <img src={greenPac} alt='greenPac' className='rounded h-[300px] md:ml-3 md:w-[50%] w-[100%] my-4 md:my-0 float-right' />

                <p className='mb-4'>As packaging increasingly becomes a priority for online merchants and consumers alike, more options are becoming available. Not only are recycled materials the order of the day but also more packaging options that can be reused or compostable. Resealable bio-based alternative envelopes, pouches and labels, are also becoming more popular for ecommerce categories.</p>

                <p className='mb-4'>Before making any environmental claims on packages, companies should check the Federal Trade Commission's <Link to="https://www.ipc.be/services/markets-and-regulations/cross-border-shopper-survey" target='_blank' className='text-[#07c9e1] underline'>(FTC's) Green Guides</Link> for guidance.</p>

                <p className='mb-4'>Sustainable packaging can have various positive impacts on an established or up-and-coming online merchant's brand, including reducing its carbon footprint, reducing waste, and establishing a positive first and long-lasting impression that can lead to increased sales and loyal customers.</p>


            </div>

        </div>
    )
}

export default LastSection
