import React from 'react'
import smartLocker from '../../../assets/images/articles/locker/Groundfloor-lockers-white-2-1024x669.jpg.webp'

const LastSection = () => {
    return (
        <div className='container lg:px-[20px] px-4 2xl:p-0 2xl:pb-14 mx-auto mb-11'>
            <div className='flex items-center justify-center flex-col pt-11 pb-4'>
                <h1 className='font-extrabold lg:text-3xl text-center text-2xl text-[#132b38]'>Smart parcel lockers are coming</h1>
            </div>
            <div className='lg:p-8 md:p-4 text-[#666666] text-justify'>
                <img src={smartLocker} alt='smartLocker' className='rounded md:w-[50%] w-[100%] md:h-[300px] lg:h-[350px] my-4 md:my-0 float-right' />
                <p className='leading-loose pt-4 2xl:pt-8'>Parcel lockers not only represent the answer to the ever-increasing volume of shipped packages; they also represent a fundamental shift in the way we think about last-mile delivery. With their ability to enhance security, convenience, and the overall efficiency and success of shipping, smart lockers are set to play a pivotal role in the future of delivery, offering benefits to consumers, retailers, and delivery companies alike. As the adoption of technology continues to evolve, expect to see these lockers become an integral part of our daily lives, simplifying the package delivery process and making our experience with parcels more convenient and efficient than ever seen before.</p>



            </div>
        </div>
    )
}

export default LastSection
