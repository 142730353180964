import React from 'react'
import ecomParcel from '../../../assets/images/articles/E_commerce_c2c/pexels-karolina-grabowska-4506231-1024x683.jpg'

const Desciption = () => {
    return (
        <div className='container lg:px-[20px] px-4 2xl:p-0 mx-auto'>
            <div className='flex items-center justify-center flex-col pt-11 pb-4'>
                <h1 className='font-extrabold lg:text-3xl text-center text-2xl text-[#132b38]'>Consumer-to-consumer e-commerce is no lockdown loaf</h1>
            </div>
            <div className='lg:p-8 md:p-4 pb-6 text-[#666666] text-justify'>

                <img src={ecomParcel} alt='ecomParcel' className='rounded h-[260px] md:ml-3 md:w-[50%] w-[100%] my-4 md:my-0 float-right' />

                <p className='mb-4'>The e-commerce surge during the COVID-19 pandemic made lots of headlines. While brick-and-mortar stores struggled to move business online, established B2C e-commerce marketplaces took full advantage of their competitive edge. And sales soared. For example, McKinsey reports that Amazon's sales in 2020 rose 51% in the UK and 32% in Germany, setting records in both countries.</p>

                <p className='mb-4'>But another e-commerce sector was also booming, albeit more quietly: consumer-to-consumer (C2C) e-commerce. C2C sites like the UK's Gumtree and France's leboncoin enjoyed over 50% growth in 2020. Lithuania's Vinted saw 65% revenue growth from 2020 to 2021.</p>


            </div>

            <div className='lg:px-8 md:px-4 mb-8 text-[#666666] text-justify'>
                <p>It appears that while some people took up painting, gardening, or baking (lockdown loaf, anyone?), others used the time at home to declutter and sell unwanted items. And they found many eager buyers. But even though the pandemic is behind us, the trend continues. Experts see opportunities for continued growth and innovation. In Europe alone, forecasts show consumer-to-consumer business taking up half the e-commerce market.</p>

                <p className='pt-4'>So why is secondhand increasingly becoming people's first choice? And where is the room for even more growth? Let's clean out the closet and have a look.</p>
            </div>
        </div>
    )
}

export default Desciption
