import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserPlus, faBoxOpen, faTruckFast } from '@fortawesome/free-solid-svg-icons'
import arrow from '../../assets/images/HomePage/banner-arrow4.png'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'


const Steps = () => {

    const { t } = useTranslation();

    return (
        <div className='container overflow-hidden'>
            <div className='w-[100%] md:absolute 2xl:top-[10.5%] lg:top-[9%] md:top-[7.4%]'>
                <div className='md:pb-4 pt-8 md:pt-0 flex justify-center items-center'>
                    <span className='md:text-white text-black font-bold md:text-[20px] text-[18px]'>{t('home.stepsTitle')}</span>
                    <img alt='arrow' src={arrow} className="md:w-[40px] hidden md:block w-[30px] md:mt-6 mt-5 md:ml-2 ml-1 " />
                </div>
                <div className='w-[100%] px-4 flex flex-col md:flex-row justify-center items-center relative overflow-hidden md:pt-0 pt-6 pb-4'>


                    <div className="md:w-40 w-[70%] md:h-[110px] h-[120px] group bg-white ease-in md:rounded-l md:rounded-r-none rounded duration-500 transform md:my-0 my-4 cursor-pointer md:shadow shadow-2xl">
                        <div className='flex flex-col items-center p-4'>
                            <Link to='https://app.irsalkom.ma/register' className='flex flex-col items-center'>
                                <FontAwesomeIcon icon={faUserPlus} className='text-4xl mb-4 group-hover:text-[#1c83b3] text-[#07c9e1]'></FontAwesomeIcon>
                                <p className='text-center font-bold group-hover:text-[#1c83b3] text-sm '>{t('home.step1')}</p>

                            </Link>
                        </div>
                    </div>

                    <div className="md:w-40 w-[70%] md:h-[110px] h-[120px] md:my-0 my-4 rounded md:rounded-none bg-white md:shadow shadow-2xl">
                        <div className='flex flex-col items-center p-4'>
                            <FontAwesomeIcon icon={faBoxOpen} className='text-4xl mb-4 text-[#07c9e1]'></FontAwesomeIcon>
                            <p className='text-center text-sm  font-bold'>{t('home.step2')}</p>
                        </div>
                    </div>

                    <div className="md:w-40 w-[70%] md:h-[110px] h-[120px] md:my-0 my-4 bg-white md:shadow shadow-2xl md:rounded-r md:rounded-l-none rounded">
                        <div className='flex flex-col items-center p-4'>
                            <FontAwesomeIcon icon={faTruckFast} className='text-4xl mb-4 text-[#07c9e1]'></FontAwesomeIcon>
                            <p className='text-center text-sm font-bold'>{t('home.step3')}</p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Steps
