import React from 'react'
import data from '../../data/EcommerceArticles.json'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'


const Section = () => {
    const { t } = useTranslation();
    return (
        <div className='container lg:px-[20px] px-4 2xl:p-0 my-8 mx-auto'>
            <div className='mb-8 flex items-center justify-center flex-col text-center'>
                <h1 className='font-extrabold md:text-3xl text-2xl md:mb-4 mb-3 text-[#132b38]'>Articles</h1>
                <hr className='border-[2px] border-[#07c9e1] w-[80px]' />
                <h4 className='text-gray-400 mt-3 md:text-[16px] text-[13px]'>{t('blogPage.sectionSubHeading')}</h4>
            </div>

            <div className='grid lg:grid-cols-3 md:grid-cols-2 gap-8 pt-0 lg:p-8 md:p-4'>
                {
                    data.map((item, index) => (
                        <div key={index} className='overflow-hidden h-auto'>
                            <Link to={item.link} className='overflow-hidden'>
                                <img className='rounded-2xl h-[250px] w-[100%] object-cover ease-in duration-500 transform hover:scale-105' src={require(`../../assets/images/blogsImgSection/${item.image}`)} alt={item.title} />
                            </Link>

                            <div className='pt-3 flex flex-col items-center justify-center'>
                                <Link to={item.link}>
                                    <h4 className='font-bold mb-4 text-center hover:text-blue-500'>{item.title}</h4>
                                </Link>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default Section
