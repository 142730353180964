import React from 'react'
import trackPhone from '../../../assets/images/articles/delivery/Android-Phone.webp'
import deliveryMan from '../../../assets/images/articles/delivery/deliveryMan.webp'

const BodySection = () => {

    return (
        <div className=''>
            <div className='container lg:px-[20px] px-4 2xl:p-0 mx-auto'>
                <div className='flex items-center justify-center flex-col pt-8 pb-4'>
                    <h1 className='font-extrabold lg:text-3xl text-center text-2xl text-[#132b38]'>On-demand delivery is very much in demand</h1>
                </div>

                <div className=' lg:p-8 md:p-4 text-[#666666] text-justify'>
                    <img src={trackPhone} alt='trackPhone' className='rounded md:w-[50%] md:ml-3 w-[100%] md:h-[300px] md:object-cover my-4 md:my-0 float-right' />

                    <p className='mb-4 lg:leading-loose'>Quick commerce has existed on the fringes of the e-commerce sector for about a decade - mainly in the form of app-based urban food delivery services. We've all seen them: those hardy souls on two wheels with insulated backpacks who bring us warm food on a chilly evening in about the time it takes to set the table.</p>

                    <p className='mb-4 lg:leading-loose'>Then came the Covid-19 pandemic. Suddenly, we had to navigate lockdowns, and just going out to the grocery store or pharmacy seemed like a bad idea. E-commerce exploded, and so did our expectations for the sheer diversity of what could be delivered to our doorsteps - and how quickly.</p>

                    <p className='lg:leading-loose'>The dark days of the pandemic are behind us, but as the saying goes: Once tasted, always wanted. Consumers now know from experience that it's possible to get all kinds of things not just same day but  same hour ,  and  they're demanding more. Much more. Quick commerce has expanded beyond food delivery and is becoming mainstream for groceries, pharmaceuticals, office supplies, small electronics, and more.</p>
                </div>

                <div className='flex items-center justify-center flex-col pt-8 pb-4'>
                    <h1 className='font-extrabold lg:text-3xl text-center text-2xl text-[#132b38]'>What is q-commerce - and how does it differ from e-commerce?</h1>
                </div>

                <div className='lg:p-8 md:p-4 text-[#666666] text-justify'>
                    <img src={deliveryMan} alt='deliveryMan' className='rounded md:ml-3 md:w-[50%] w-[100%] md:h-[300px] md:object-cover mb-4 md:my-0 float-right' />

                    <p className='mb-4 lg:leading-loose'>Quick commerce - also known as "on-demand delivery" or "q-commerce" - is an accelerated model of e-commerce. The premise remains largely the same, but speed becomes the defining factor. Q-commerce delivery windows are measured not in days but in minutes - generally 60 or less.
                    </p>

                    <p className='mb-4 lg:leading-loose'>These faster timelines are forcing e-commerce businesses to innovate their storage and last-mile delivery models. The global e-commerce sector continues its unstoppable rise, nosing past the 3-trillion-dollar mark in 2023. Quick commerce is claiming an ever-growing share of that as it expands into fast-moving consumer goods, small electronics, fashion - and even furniture!</p>
                </div>
            </div>
        </div>
    )
}

export default BodySection
