import { useTranslation } from 'react-i18next'

const ShippingData = () => {
    const { t } = useTranslation();

    const data = [
        {
            "title": t('shipingOptions.titleOfOption1'),
            "description": t('shipingOptions.descriptionOption1'),
            "img": "door-to-door.png"
        },
        {
            "title": t('shipingOptions.titleOfOption2'),
            "description": t('shipingOptions.descriptionOption2'),
            "img": "airplane.png"
        },
        {
            "title": t('shipingOptions.titleOfOption3'),
            "description": t('shipingOptions.descriptionOption3'),
            "img": "logistics.png"
        },
        {
            "title": t('shipingOptions.titleOfOption4'),
            "description": t('shipingOptions.descriptionOption4'),
            "img": "freight-shipping.png"
        }
    ];

    return data;
}

export default ShippingData;