import React from 'react'
import { Link } from 'react-router-dom'
import shopImg from '../../../assets/images/articles/Metaverse/meta-retail-concept-collage.jpg'

const Explain = () => {
    return (
        <div className='container lg:px-[20px] px-4 2xl:p-0 mx-auto'>
            <div className='flex items-center justify-center flex-col pb-4'>
                <h1 className='font-extrabold lg:text-3xl text-center text-2xl text-[#132b38]'>What will virtual shopping look like?</h1>
            </div>

            <div className='lg:p-8 md:p-4 pb-6 text-[#666666] text-justify'>

                <img src={shopImg} alt='shop' className='rounded h-[250px] lg:h-[350px] md:w-[50%] w-[100%] my-4 md:my-0 md:ml-3 float-right' />

                <p className='mb-4'>Not even those who promote the metaverse are able to offer a reliable definition of what it is. That hasn't stopped Silicon Valley's team of tech nerds in California from plugging away at the 'next big thing' for a while now, busily upgrading the internet to Web 3.0 - a virtual cosmos were the laws of physics no longer appear to apply.</p>

                <p className='mb-4'>But what does the metaverse look like? And what will shopping in the metaverse be like? Maybe something like this: one moment you're scrolling through some PowerPoint slides in an online meeting and the next you're strolling along a busy virtual shopping street. You pop in and out of your favorite stores, which just happen to be right next to each other, like the favorites tab in your browser. After you make a purchase, instead of carrying it out of the store in a bag, your avatar hands it to a virtual IRSALKOM courier, who whisks it off to the online distribution center, where the virtual and real worlds meet and the physical copy of the <Link to="https://en.wikipedia.org/wiki/Non-fungible_token" target='_blank' className='text-[#07c9e1] underline'>non-fungible</Link> token (NFT) you just bought makes its way to your real-world home.</p>


            </div>

            <div className='lg:p-8 md:p-4 pt-0 text-[#666666] text-justify'>
                <p className='mb-4'>
                    Tech futurist and self-dubbed 'Godmother of the Metaverse' Cathy Heckl imagines virtual “vending machines” appearing out of nowhere as soon as you think of a product you need. It may sound a bit far-fetched and even fantastical, but many like Cathy believe it's only a matter of time.
                </p>

                <p className='mb-4'>
                    In the metaverse, a space where the physical and virtual worlds meet, various technologies and digital applications will allow people to move freely through worlds, such as concerts and venues, and stores. Just put on virtual reality glasses and enter is one idea, at least. Technologies like Blockchain (a kind of digital ledger), NFTs, and Virtual Realities (VR) will make life in the metaverse possible. <Link to="https://en.wikipedia.org/wiki/Blockchain" target='_blank' className='text-[#07c9e1] underline'>Blockchain</Link> and NFTs will provide digital proof of ownership, and a mix of virtual and augmented realities will create the experience, much like in many life-like video games today.
                </p>
            </div>
        </div>
    )
}

export default Explain
