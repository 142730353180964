import React from 'react'
import MainLayout from '../../layouts/MainLayout'
import NewsSlider from '../../componenets/News/NewsSlider'
import BodySection from '../../componenets/News/BodySection'

const News = () => {
    return (
        <MainLayout>
            <NewsSlider />
            <BodySection />
        </MainLayout>
    )
}

export default News
