import React, { lazy } from 'react'
import MainLayout from '../../layouts/MainLayout'

const Description = lazy(() => import('../../componenets/News/RedSea/Description'));
const BodyCOntent = lazy(() => import('../../componenets/News/RedSea/BodyCOntent'));
const LastSection = lazy(() => import('../../componenets/News/RedSea/LastSection'));


const News1 = () => {
    return (
        <MainLayout>
            <Description />
            <BodyCOntent />
            <LastSection />
        </MainLayout>
    )
}

export default News1
