import React from 'react'

const LastSection = () => {
    return (
        <div className='container lg:px-[20px] px-4 2xl:p-0 mx-auto'>
            <div className='flex items-center justify-center flex-col py-4'>
                <h1 className='font-extrabold Lg:text-3xl text-center text-2xl text-[#132b38]'>The convergence of online and offline</h1>
            </div>

            <div className='md:flex md:flex-wrap lg:p-8 md:p-4 py-6'>

                <div className='md:w-[50%] h-auto md:pr-6 text-[#666666] text-justify'>
                    <p className='mb-4'>Tomorrow's most successful companies, whether they are targeting consumers or business spend, won't be the ones that have an online strategy and an offline equivalent. They will be the ones that focus on an omni-channel strategy that incorporates both.</p>

                    <p className='mb-4'>
                        These business strategies reflect the modern wish for an integrated approach to buying. Today, when someone buys something from a brand, they want the same brand experience online that they get offline.
                    </p>
                </div>

                <div className='md:w-[50%] h-auto text-[#666666] text-justify'>
                    <p className='mb-4'>In China, for example, ecommerce conglomerate Alibaba's 'new retail' strategy has seen it open bricks and mortar grocery stores and pop-up shops in addition to partnerships with around 600,000 smaller stores.</p>

                    <p className='mb-4'>
                        With its trial of cashless checkouts, fast fashion retailer Zara is a good example of a retailer that is deploying technology to change the in-store customer experience. Others are using artificial intelligence and augmented reality to bring aspects of the online experience - such as the ability to personalize and design products - to their bricks and mortar operations.
                    </p>
                </div>
            </div>

            <div className='lg:p-8 md:p-4 pt-0 mb-8 text-[#666666] text-justify'>
                <p>For B2B ecommerce customers, online purchasing will play an increasingly large role in their work, and these same four trends apply just as much to them as they do to private consumers. When purchasing decisions are part of your professional life, it becomes even more important to pay attention to how the future is shaping up.</p>
            </div>
        </div>
    )
}

export default LastSection
