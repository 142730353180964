import React from 'react'
import sliderImg from '../../../assets/images/articles/Metaverse/image.webp'

const Slider = () => {
    return (
        <div className='md:h-[450px] 2xl:h-[550px] h-[350px] bg-cover bg-center relative' style={{ backgroundImage: `url(${sliderImg})` }}>
            <div className='container lg:px-[20px] px-4 2xl:p-0 mx-auto flex flex-wrap'>

                <div className='lg:w-[45%] md:w-[80%] flex flex-col text-white lg:p-8 md:px-4 md:pt-[20%] lg:pt-[10%]  pt-[30%]'>
                    <h1 className='font-extrabold lg:text-3xl md:text-2xl text-[20px]'>The metaverse will change the way we shop - will it change the way we ship?</h1>

                    <p className='md:pt-11 pt-8 text-sm 2xl:text-base font-bold text-justify'>The tech sector says the metaverse is the next big thing. Critics will tell you it'll never catch on. But big retailers are buying in. Too far-fetched for logistics to start taking a look? We don't think so.</p>
                </div>

                <div className='w-[55%] hidden lg:block'></div>
            </div>
        </div>
    )
}

export default Slider
