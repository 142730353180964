import React, { useState } from 'react'

import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const TrackShipment = () => {
  const [ref, setRef] = useState('');
  const { t } = useTranslation();

  const navigate = useNavigate();

  const searchHandler = async (track) => {
    if (track) {
      const response = await fetch(`https://app.irsalkom.ma/api/tracking?ref=${track}`)
      const data = await response.json();

      console.log(data)
      if (response.ok && data) {
        navigate('/Tracking-status', { state: { results: data, number: track } })

      } else {
        // alert if the tracking number incorrect
        toast.error(t('trakingAlert.incorrectNumber'),
          { position: "top-center" });
      }
    } else {
      // alert if user did not enter tracking number 
      toast.error(t('trakingAlert.numberNotEntered'),
        { position: "top-center" });
    }

  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      searchHandler(ref);  // Trigger searchHandler on Enter key press
    }
  };

  return (
    <div className='pt-16'>
      <input type='text' placeholder={t('home.placeholder')} name="trakingNumber" className='lg:w-[470px] md:w-[350px] w-[260px] md:px-6 px-3 py-3 text-sm md:text-[17px] outline-none border-2 border-r-0 rounded-l' value={ref} onChange={(e) => setRef(e.target.value)} onKeyDown={handleKeyDown} />
      <button type='submit' className='bg-[#07c9e1] text-white font-bold md:px-6 border-2 border-l-0 px-4 py-3 cursor-pointer rounded-r text-sm md:text-[17px]' onClick={() => searchHandler(ref)} >{t('home.submit')}</button>



      <ToastContainer position="top-center" />
    </div>
  )
}

export default TrackShipment
