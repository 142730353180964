import React from 'react'
import sliderImg from '../../assets/images/slider/morocco.webp'

const RegionSlide = ({ title }) => {
    return (
        <div className='h-[450px] bg-cover bg-center' style={{ backgroundImage: `url(${sliderImg})` }}>
            <div className='container flex flex-col justify-center items-center h-[100%] mx-auto'>
                <h1 className='lg:text-4xl md:text-3xl text-2xl text-white text-center font-bold'>{title}</h1>
            </div>
        </div>
    )
}

export default RegionSlide
