import React from 'react'
import MainLayout from '../../../layouts/MainLayout'
import Slider from '../../../componenets/Articles/Trends/Slider'
import Description from '../../../componenets/Articles/Trends/Description'
import BodySection from '../../../componenets/Articles/Trends/BodySection'
import GrowthEcom from '../../../componenets/Articles/Trends/GrowthEcom'
import LastSection from '../../../componenets/Articles/Trends/LastSection'

const Trends = () => {
    return (
        <MainLayout>
            <Slider />
            <Description />
            <BodySection />
            <GrowthEcom />
            <LastSection />
        </MainLayout>
    )
}

export default Trends
