import React, { lazy } from 'react'
import MainLayout from '../../layouts/MainLayout'

// lazy load the components
const Description = lazy(() => import('../../componenets/News/Cryosure-interview/Description'));
const BodyContent = lazy(() => import('../../componenets/News/Cryosure-interview/BodyContent'));
const LastSection = lazy(() => import('../../componenets/News/Cryosure-interview/LastSection'));



const News2 = () => {
    return (
        <MainLayout>
            <Description />
            <BodyContent />
            <LastSection />
        </MainLayout>
    )
}

export default News2
