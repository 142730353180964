import React from 'react'
import MainLayout from '../../../layouts/MainLayout'
import Slider from '../../../componenets/Articles/Packaging/Slider'
import Description from '../../../componenets/Articles/Packaging/Description'
import ExplainSection from '../../../componenets/Articles/Packaging/ExplainSection'
import LastSection from '../../../componenets/Articles/Packaging/LastSection'

const Packaging = () => {
    return (
        <MainLayout>
            <Slider />
            <Description />
            <ExplainSection />
            <LastSection />
        </MainLayout>
    )
}

export default Packaging
