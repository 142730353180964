import React from 'react'
import futurEcomImg from '../../../assets/images/articles/Trends/2128.jpg'
const Description = () => {
    return (
        <div className='container lg:px-[20px] px-4 2xl:p-0 mx-auto'>
            <div className='flex items-center justify-center flex-col pt-11 pb-4'>
                <h1 className='font-extrabold lg:text-3xl text-center text-2xl text-[#132b38]'>Are you ready for the future of ecommerce?</h1>
            </div>

            <div className='lg:flex lg:flex-wrap lg:p-8 md:p-4 pb-6'>

                <div className='lg:w-[50%] h-auto lg:pr-6 py-3 lg:py-0'>
                    <img src={futurEcomImg} alt='futur Ecom' className='rounded md:h-[390px] md:ml-3 h-[300px] w-[100%] object-cover' />
                </div>

                <div className='lg:w-[50%] text-[#666666] text-justify'>
                    <p className='mb-4 pt-4'>Online buying has become a normal part of our personal lives, and it is starting to find its way into our working lives as well. After all, corporate purchasers of goods and services are also consumers. They want the same easy experience of purchasing online for their business that they get outside the workplace.</p>

                    <p className='mb-4'>There are plenty of statistics about the boom in consumer ecommerce from 2020; one that stands out from IRSALKOM's realm is a 40 percent increase in B2C ecommerce volumes. At the same time, however, B2B sales in ecommerce are also booming. In fact, research firm Forrester forecasts that business ecommerce in the US alone is on track to reach $1.2 trillion in 2021.</p>

                    <p className='mb-4'>It's all part of the massive change brought on by the outsized role of ecommerce in our lives - here's a look at four trends we can expect to shape the future of B2C and B2B purchasing.

                    </p>
                </div>

            </div>
        </div>
    )
}

export default Description
