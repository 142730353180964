import React, { lazy } from 'react'
import MainLayout from '../../layouts/MainLayout'

const ContactSlider = lazy(() => import('../../componenets/contact/ContactSlider'));
const Details = lazy(() => import('../../componenets/contact/Details'));
const Form = lazy(() => import('../../componenets/contact/Form'));

const Contact = () => {
    return (
        <MainLayout>
            <ContactSlider />
            <Details />
            <Form />
        </MainLayout>
    )
}

export default Contact
