import React from 'react'
import MainLayout from '../../layouts/MainLayout'
import Description from '../../componenets/News/Mauritania_tax/Description'

const News3 = () => {
    return (
        <MainLayout>
            <Description />
        </MainLayout>
    )
}

export default News3
