import React from 'react'

const ContentSection = () => {
    return (
        <div className='container lg:px-[20px] px-4 2xl:p-0 md:mt-14 my-8 mx-auto'>
            <div className=' flex items-center justify-center flex-col text-center'>
                <h1 className='font-extrabold md:text-3xl text-[20px] md:mb-14 mb-8 text-[#132b38]'>Qu'entend-on par marchandises dangereuses ?</h1>
            </div>
            <div className='lg:px-8 md:px-4 text-[#666666]'>
                <p className='md:text-justify'>Les marchandises dangereuses correspondent à tout article ou substance qui, en cas de manipulation ou conditionnement inapproprié(e), pourrait présenter un risque pour la santé ou la sécurité, endommager d'autres colis, ou nuire à l'environnement. Il existe neuf catégories de marchandises dangereuses. Savoir dans laquelle se classe votre colis vous donnera une idée plus précise de la manière dont vous devez l'emballer, l'étiqueter et le transporter.</p>

                <h3 className='font-bold text-[#242a65] my-8'>Quelles sont les marchandises considérées comme dangereuses ?</h3>

                <p className='md:text-justify'>Vous pourriez être surpris par certains des produits qui sont considérés comme des marchandises dangereuses, tels que les aérosols, parfums ou tout objet contenant des piles ou batteries au lithium - comme les téléphones ou ordinateurs portables.</p>
            </div>
        </div>
    )
}

export default ContentSection
