import React from 'react';
import MainLayout from '../../layouts/MainLayout';
import HomeSlider from '../../componenets/Home/HomeSlider';
import Steps from '../../componenets/Home/Steps';
import Intro from '../../componenets/Home/Intro';
import ServicesSection from '../../componenets/Home/ServicesSection';
import ShipingOptions from '../../componenets/Home/ShipingOptions';
import MoroccanMap from '../../componenets/Home/MoroccanMap';
import ContactSection from '../../componenets/Home/ContactSection';

const Home = () => {
    return (
        <div>
            <MainLayout>
                <HomeSlider />
                <Steps />
                <Intro />
                <ServicesSection />
                <ShipingOptions />
                <MoroccanMap />
                <ContactSection />
            </MainLayout>
        </div>
    );
};

export default Home;

