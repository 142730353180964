import React from 'react'
import { Link } from 'react-router-dom'

const LastSection = () => {
    return (
        <div className='container lg:px-[20px] px-4 2xl:p-0 mx-auto mb-4'>
            <div className='flex items-center justify-center flex-col lg:px-8 md:px-4 py-8 md:py-0'>
                <h1 className='font-extrabold lg:text-3xl text-center text-2xl text-[#132b38]'>Is metaverse ecommerce the next online shopping megatrend?</h1>
            </div>
            <div className='lg:p-8 md:p-4 text-[#666666] text-justify'>
                <p className='pb-4'>No matter what metaverse ecommerce looks like, people are ready to embrace it. In fact, a recent poll by McKinsey indicates that shopping in the metaverse will likely be the most popular activity. More respondents (48%) showed interest in a virtual reality (VR) shopping experience than in VR gaming (40%). These results are on par with other research, such as 2020 study in Singapore and Australia showing that around <Link to="https://www.greenbook.org/insights/focus-on-apac/how-likely-are-consumers-in-singapore-and-australia-to-embrace-the-metaverse" target="_blank" className='text-[#07c9e1] underline'>half of consumers</Link> are currently interested in virtual shopping.</p>

                <p className='pb-4'>Many experts say that the Metaverse doesn't just represent the future of the Internet but that it will eventually become the most powerful economic force in the world. A multi-trillion-dollar industry. It helps that big tech companies like Meta, Microsoft, and Google are driving development.</p>

                <p className='pb-4'>
                    But where is this all going? For the most part, we don't know yet. A lot is still unknown about the metaverse, especially the deeper into the details you dig. No one can say for certain whether the metaverse soon dies a natural death, as did hype around the virtual reality gaming world  Second Life . Some describe this moment like watching a film you've seen before but you still don't know how it will end.
                </p>
            </div>

        </div>
    )
}

export default LastSection
