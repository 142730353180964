import React from 'react'
import MainLayout from '../../layouts/MainLayout'
import MainSection from '../../componenets/prices/MainSection'


const Prices = () => {
    return (
        <MainLayout>
            <MainSection />
        </MainLayout>
    )
}

export default Prices
