import React from 'react'
import MainLayout from '../../../layouts/MainLayout'
import Slider from '../../../componenets/ShippingSupportArticles/PackGoods/Slider'
import BodySection from '../../../componenets/ShippingSupportArticles/PackGoods/BodySection'
import Steps from '../../../componenets/ShippingSupportArticles/PackGoods/Steps'

const PackGoods = () => {
  return (
    <MainLayout>
      <Slider />
      <BodySection />
      <Steps />
    </MainLayout>
  )
}

export default PackGoods
