import React, { useState, useEffect } from 'react'
import { NavLink, Link, useLocation } from 'react-router-dom'
import logo from '../../assets/images/logo/LOGO_IRSALKOM-02.png'
import { useTranslation } from 'react-i18next'
import LanguageSelector from './LanguageSelector'
import { FaBars, FaXmark } from "react-icons/fa6";
import ServicesLgScreen from './ServicesLgScreen'
import ServicesSmallScreen from './ServicesSmallScreen'
import ExploreLgScreen from './ExploreLgScreen'
import ExploreSmallScreen from './ExploreSmallScreen'



const Navbar = () => {
    const location = useLocation();
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    // Update scroll behavior when navigation occurs
    useEffect(() => {
        const handleRouteChange = () => {
            setOpen(false); // Close the navbar when navigating
            document.body.style.overflow = 'auto'; // Enable scroll
        };

        // Clean up event listener on unmount
        return () => {
            handleRouteChange();
        };
    }, []);

    // Toggle navbar open/close state
    const handleButtonClick = () => {
        setOpen(!open);
        document.body.style.overflow = open ? 'auto' : 'hidden'; // Toggle scroll behavior
    };

    return (
        <div className='bg-white shadow z-50 fixed top-0 left-0 right-0'>
            {open && <div className="fixed top-0 left-0 w-full h-full bg-black opacity-75 z-10"></div>}
            <nav className='container lg:px-[20px] px-4 mx-auto 2xl:px-0'>
                <div className='flex items-center justify-between py-2 lg:py-0'>
                    <div className='flex items-center gap-1'>
                        <Link to="/">
                            <img src={logo} className="lg:w-[200px] w-[160px]" alt="logo" />
                        </Link>
                    </div>

                    <ul className={`absolute lg:relative z-50 left-0 right-0 w-[60%] md:w-[50%] lg:w-auto lg:py-0 pl-2 bg-white lg:top-auto lg:left-auto lg:right-auto transition-all duration-500 ease-in lg:flex justify-between ${open ? 'top-0 h-[100vh]' : 'left-[-490px]'}`}>
                        <li className={`pb-16 lg:pb-0 relative ${open ? 'block' : 'hidden'}`}>

                            <div onClick={handleButtonClick} className={`absolute top-2 right-1 cursor-pointer ml-4 text-[30px] md:text-3xl`}>
                                {
                                    open && <FaXmark />
                                }
                            </div>
                        </li>
                        <li className='relative group pb-8 lg:pb-0'>
                            <NavLink to='/' className={`cursor-pointer mr-3 font-bold px-3 hover:text-[#1374b4] ${location.pathname === '/' ? 'text-[#1374b4]' : 'text-black'}`}>{t('navbar.home')}</NavLink>
                            <span
                                className="group-hover:scale-x-100 absolute -top-2 lg:w-[45px] w-0 left-3 -right-2 h-1 origin-left scale-x-0 rounded-full bg-[#07c9e1] transition-transform duration-300 ease-out"
                            />
                        </li>
                        <li className='relative group pb-8 lg:pb-0'>
                            <NavLink to='/About' className={`cursor-pointer mr-3 font-bold px-3 hover:text-[#1374b4] ${location.pathname === '/About' ? 'text-[#1374b4]' : 'text-black'}`}>{t('navbar.about')}</NavLink>
                            <span
                                className="group-hover:scale-x-100 absolute -top-2 lg:w-[70px] w-0 left-3 -right-2 h-1 origin-left scale-x-0 rounded-full bg-[#07c9e1] transition-transform duration-300 ease-out"
                            />
                        </li>

                        <li className='pb-8 lg:pb-0 hidden lg:block'>
                            <ServicesLgScreen />
                        </li>
                        <li className='pb-4 lg:pb-0 lg:hidden'>
                            <ServicesSmallScreen />
                        </li>
                        <li className=' pb-8 lg:pb-0 hidden lg:block'>
                            <ExploreLgScreen />
                        </li>

                        <li className='pb-4 lg:pb-0 lg:hidden'>
                            <ExploreSmallScreen />
                        </li>
                        <li className='relative group pb-8 lg:pb-0'>
                            <NavLink to='/Contact' className={`cursor-pointer mr-3 font-bold px-3 hover:text-[#1374b4] ${location.pathname === '/Contact' ? 'text-[#1374b4]' : 'text-black'}`}>{t('navbar.contact')}</NavLink>
                            <span
                                className="group-hover:scale-x-100 absolute -top-2 lg:w-[80px] w-0 left-3 -right-2 h-1 origin-left scale-x-0 rounded-full bg-[#07c9e1] transition-transform duration-300 ease-out"
                            />
                        </li>
                        <li className='relative group pb-8 lg:pb-0'>
                            <Link to='https://app.irsalkom.ma/login' className='font-bold px-3 mr-3 lg:hidden block hover:text-[#07c9e1] text-[#1374b4] '>
                                {t('navbar.login')}
                            </Link>
                        </li>

                        <div className='lg:hidden pl-2'>
                            <LanguageSelector />
                        </div>
                    </ul>
                    <div className='hidden lg:block'>
                        <LanguageSelector />
                    </div>
                    <div className='flex'>
                        <Link to='https://app.irsalkom.ma/login' className='font-bold md:px-4 px-2 lg:py-2 py-1 hidden lg:block hover:text-[#07c9e1] text-[#1374b4] '>
                            {t('navbar.login')}
                        </Link>

                        <Link to='https://app.irsalkom.ma/register' className='font-bold md:px-4 px-2 lg:py-2 py-1 hover:border-[1px] hover:border-[#07c9e1] hover:bg-white bg-[#07c9e1] text-white hover:text-[#07c9e1] rounded'>

                            {t('navbar.register')}
                        </Link>

                        <div onClick={handleButtonClick} className={`cursor-pointer ml-4 lg:hidden text-3xl`}>
                            {
                                open ? '' : <FaBars />
                            }
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default Navbar
