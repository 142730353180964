import React from 'react'
import logistic from '../../../assets/images/articles/logistics/warehousing.webp'
const Description = () => {
    return (
        <div className='container lg:px-[20px] px-4 2xl:p-0 mx-auto'>
            <div className='flex items-center justify-center flex-col pt-11 pb-4'>
                <h1 className='font-extrabold lg:text-3xl text-center text-2xl text-[#132b38]'>3PL vs. 4PL: the right logistics for each phase of growth</h1>
            </div>
            <div className='lg:p-8 md:p-4 pb-6 text-[#666666] text-justify'>
                <img src={logistic} alt='logistic' className='rounded md:w-[50%] md:ml-3 w-[100%] h-[200px] lg:h-[350px] my-4 md:my-0 float-right' />

                <p className='mb-4'>Dwight D. Eisenhower - Supreme Commander of Allied Forces in Europe during the Second World War and later US President - famously dismissed the value of a vision without the underlying infrastructure: "I have always found that plans are useless, but planning is indispensable."</p>

                <p className='mb-4'>Entrepreneurial success begins with a business that makes a great product (the first party) and customers who want it (the second party). But too much success leads to logistical challenges that can undermine that success. <strong className='text-black'>Enter third-party</strong> logistics (3PL). What is 3PL? The short answer is outsourced logistics services, such as warehouse management and freight transportation services. 3PLs with the right expertise can even handle procurement, freight consolidation, packaging, and customs brokerage for cross-border sales. 3PL takes the pressure off your business, freeing you up to focus on what you do best</p>




            </div>
            <div className='lg:p-8 md:p-4  mb-8 text-[#666666] text-justify'>
                <p>But if your business continues to thrive, you may find yourself working with many different 3PLs. Most global companies understand this. They manage multiple business units, partners, and service providers to ensure success. This is where  fourth-party logistics  (4PL) comes in. What is 4PL? Also known as a  lead logistics provider  (LLP), a 4PL provider coordinates all the moving parts. 4PL providers act as top-level supply chain strategists, managing everything from resources to technology to infrastructure. Critically, 4PLs are the single point of contact for both the customers they ultimately serve and the 3PL providers they directly manage.</p>
            </div>
        </div>
    )
}

export default Description
