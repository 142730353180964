import React from 'react'
import colisImg from '../../../assets/images/shippingSupport/colisPack.jpeg'

const BodySection = () => {
    return (
        <div className='container lg:px-[20px] h-auto px-4 2xl:p-0 md:my-14 my-8 mx-auto'>
            <div className=' flex items-center justify-center flex-col text-center'>
                <h1 className='font-extrabold md:text-3xl text-[20px] md:mb-14 mb-8 text-[#132b38]'>Comment bien emballer votre colis en 4 étapes</h1>
            </div>
            <div className='lg:px-8 md:px-4 text-[#666666]'>
                <img src={colisImg} alt='trackPhone' className='rounded md:w-[30%] md:ml-3 w-[100%] md:h-[250px] md:object-cover my-4 md:my-0 float-right' />
                <p className='md:text-justify md:leading-loose'>Vous souhaitez expédier un colis, mais n'êtes pas sûr de comment l'emballer ? Pour éviter que votre colis arrive en mauvais état ou soit bloqué lors de l'expédition, soigner son emballage est primordial ! Qu'il s'agisse d'un vêtement que vous vendez sur Vinted, une œuvre d'art, d'un objet ou encore de pots de confitures, choisir les bons emballages pour la protection de vos marchandises est très important lors du transport de celles-ci. Choisir les bons cartons, ou les bonnes palettes pour vos articles vous évitera tout désagrément et améliorera la satisfaction de vos clients finaux. Que vous expédiez un objet en express ou en standard, tous nos conseils s'appliquent aux deux modes de livraison.</p>
            </div>
        </div>
    )
}

export default BodySection
