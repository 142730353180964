import React from 'react'
import Support from '../../data/Support'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next'

const BodySection = () => {
    const { t } = useTranslation();
    const data = Support();
    return (
        <div className='container lg:px-[20px] px-4 2xl:p-0 my-8 mx-auto'>
            <div className='mb-8 flex items-center justify-center flex-col text-center'>
                <h1 className='font-extrabold md:text-3xl text-[20px]  md:mb-4 mb-3 text-[#132b38]'>{t('ShippingSupport.sectionTitle')}</h1>
                <hr className='border-[2px] border-[#07c9e1] w-[80px]' />
            </div>

            <div className='grid lg:grid-cols-3 md:grid-cols-2 gap-8 pt-0 lg:p-8 md:p-4'>

                {
                    data.map((item, index) => (
                        <div key={index} className='overflow-hidden rounded-lg shadow-lg h-auto'>
                            <Link to={item.link} className='overflow-hidden'>
                                <img className='rounded-t-lg h-[250px] w-[100%] object-cover ease-in duration-500 transform hover:scale-105' src={require(`../../assets/images/shippingSupport/${item.img}`)} alt={item.title} />
                            </Link>

                            <div className='p-3 flex flex-col items-center justify-center h-[100px]'>
                                <Link to={item.link}>
                                    <h4 className='font-bold mb-4 text-center hover:text-blue-500'>{item.title}</h4>
                                </Link>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default BodySection
