import React from 'react'
import MainLayout from '../../../layouts/MainLayout'
import Slider from '../../../componenets/Articles/Delivery/Slider'
import BodySection from '../../../componenets/Articles/Delivery/BodySection'
import LastSection from '../../../componenets/Articles/Delivery/LastSection'


const Delivery = () => {
    return (
        <MainLayout>
            <Slider />
            <BodySection />
            <LastSection />
        </MainLayout>
    )
}

export default Delivery
