import React, { useState } from 'react'
import { NavLink } from 'react-router-dom';
import { FaSortDown, FaSortUp } from "react-icons/fa";
import { useTranslation } from 'react-i18next'

const ExploreSmallScreen = () => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="relative inline-block text-left">
            <div>
                <button
                    className="flex w-full justify-start py-2 font-bold px-3 cursor-pointer hover:text-[#1374b4]"
                    onClick={() => setIsOpen(!isOpen)}
                >
                    {t('navbar.explore')}
                    {
                        isOpen ? <FaSortDown className="mt-0 text-[20px]" /> : <FaSortUp className=" mt-2 text-[20px]" />
                    }

                </button>
            </div>

            {
                isOpen && <div className="" >
                    <div className="py-1" role="none">
                        <NavLink to='/Blog' className="block hover:text-[#07c9e1] font-bold px-4 py-2 text-sm">
                            Blog
                        </NavLink>
                        <NavLink to='/News' className=" block hover:text-[#07c9e1] font-bold px-4 py-2 text-sm">
                            {t('navbar.news')}
                        </NavLink>

                        <NavLink to='/shipping_support' className=" block hover:text-[#07c9e1] font-bold px-4 py-2 text-sm">
                            {t('footer.shipping_support')}
                        </NavLink>
                    </div>
                </div>
            }
        </div>
    )
}

export default ExploreSmallScreen
