import React from 'react'

const Explain = () => {
    return (
        <div className='container lg:px-[20px] px-4 2xl:p-0 mx-auto'>
            <div className='flex items-center justify-center flex-col py-4 pb-4'>
                <h1 className='font-extrabold lg:text-3xl text-center text-2xl text-[#132b38]'>C2C e-commerce then and now</h1>
            </div>
            <div className='lg:p-8 md:p-4 text-[#666666] text-justify'>
                <p className='py-4 lg:pt-0'>The consumer-to-consumer market has essentially been around since trade began, so it's no surprise that it went online not long after the internet made it possible. eBay launched almost 30 years ago in 1995, only four years after the World Wide Web became publicly available.</p>

                <p className='py-4 lg:pt-0'>Today, online platforms where buyers can sell and sellers can buy are everywhere. Etsy, Facebook Marketplace, Depop, and Taobao are just a few of the most popular sites around the world, in addition to the ones named above.</p>
            </div>
        </div>
    )
}

export default Explain
