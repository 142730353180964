import React from 'react'
import sliderImg from '../../../assets/images/articles/logistics/image.jpg'


const Slider = () => {
    return (
        <div className='lg:h-[400px] 2xl:h-[500px] h-[300px] bg-cover bg-center relative' style={{ backgroundImage: `url(${sliderImg})` }}>
            <div className='container lg:px-[20px] px-4 flex flex-wrap 2xl:px-0 mx-auto'>
                <div className='w-[55%] hidden lg:block'></div>

                <div className='lg:w-[45%] md:w-[80%] flex flex-col text-white lg:px-8 md:px-4 lg:pt-28 2xl:pt-36 pt-20'>
                    <h1 className='font-extrabold text-3xl'>3PL / 4PL Logistics</h1>

                    <p className='lg:pt-11 pt-8 font-bold text-sm 2xl:text-base text-justify'>Fast-growing businesses often feel overwhelmed by the increasing complexity of their logistics. Are 3PL or 4PL services the answer? Here we explain what these terms mean and how they differ, explore the benefits of each, and identify the factors for selecting which one is right for you.</p>
                </div>
            </div>
        </div>
    )
}

export default Slider
