import React from 'react'
import wrapPackage from "../../../assets/images/articles/horizonEcommerce/hands-typing-package-thread-close-up.webp"
import { Link } from 'react-router-dom'
const ExplainSection = () => {
    return (
        <div className='container lg:px-[20px] px-4 2xl:p-0 mx-auto'>
            <div className='flex items-center justify-center flex-col my-4'>
                <h1 className='font-extrabold lg:text-3xl text-center text-2xl text-[#132b38]'>Back to (the new) normal is a mixed bag</h1>
            </div>

            <div className='lg:p-8 md:p-4 pb-6 text-[#666666] text-justify'>

                <img src={wrapPackage} alt='wrapPackage' className='rounded md:w-[50%] w-[100%] h-[200px] lg:h-[350px] my-4 md:my-0 float-right' />

                <p className='mb-4 2xl:pt-4'>Although the numbers have been realigned with pre-pandemic forecasts, e-commerce growth has slowed, and the long-lasting effects are varied. According to  <Link to="https://www.emarketer.com/content/global-retail-ecommerce-forecast-2023#page-report/" target='_blanck' className='text-[#07c9e1] underline'>eMarketer</Link>, Retail e-commerce sales are expected to grow by 8.9% in 2023 and hover around that figure for the foreseeable future. Before Covid, growth between 2015 and 2019 fluctuated between 20% and 28%. </p>

                <p className='mb-4'>A <Link to="https://www.imf.org/en/Blogs/Articles/2022/03/17/pandemics-e-commerce-surge-proves-less-persistent-more-varied" target='_blanck' className='text-[#07c9e1] underline'>joint study</Link> by the International Monetary Fund (IMF) and the Mastercard Economics Institute found significant variation by economy and industry. The share of online spending rose and fell most dramatically in those economies and sectors where e-commerce was already thriving before the pandemic.</p>

                <p className=''>
                    Nevertheless, we anticipate higher parcel volumes in the future. Even if the pace has slowed, e-commerce continues to be a vital and growing sector of the global economy.
                </p>
                <p>
                    Current challenges like geopolitical tensions and trade barriers have led to the need for reorganizing supply chains, but they haven't doused the sector's inherent dynamism. For example, cross-border online trade within Europe is stable despite the Ukraine war, inflation, the energy crisis, and international economic-political tensions.
                </p>
            </div>
            <div className='lg:px-8 md:p-4 mb-8 text-[#666666] text-justify'>
                <p>No one expects the effects of the pandemic to simply disappear. Sales in some product categories are now noticeably higher. For example, many people have gotten pets in recent years, pushing up demand for online pet food purchases. And new marketplace models - like the customer-to-customer (C2C) secondhand fashion community Vinted - are gaining traction. The IMF and Mastercard also see potential for e-commerce in industries with lower levels of digital maturity, such as retail, restaurants, and healthcare, particularly in less developed markets.</p>
            </div>
        </div>
    )
}

export default ExplainSection
