import React from 'react'
import MainLayout from '../../layouts/MainLayout'
import TermsSlide from '../../componenets/TermsOfSales/TermsSlide'
import BodySection from '../../componenets/TermsOfSales/BodySection'


const TermsOfSales = () => {
    return (
        <MainLayout>
            <TermsSlide />
            <BodySection />
        </MainLayout>
    )
}

export default TermsOfSales
