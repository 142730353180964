import React from 'react'
import sliderImg from '../../../assets/images/articles/locker/locker.webp'

const Slider = () => {
    return (
        <div className='md:h-[400px] h-[250px] bg-cover bg-center relative' style={{ backgroundImage: `url(${sliderImg})` }}>
            <div className='container lg:px-[20px] px-4 2xl:p-0 mx-auto'>

                <div className='w-[100%] h-[100%] md:pt-[25%] lg:pt-[15%] 2xl:pt-[12%] pt-28 flex justify-center items-center text-white'>
                    <h1 className='font-extrabold text-center lg:text-3xl text-[20px]'>Delivering the Future: The Rise of Smart Parcel Lockers</h1>
                </div>
            </div>
        </div>
    )
}

export default Slider
