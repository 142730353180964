import React from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const ExploreContent = () => {
    const { t } = useTranslation();
    return (
        <div className="w-[150px] bg-white shadow-2xl rounded-xl">
            <div className="">
                <NavLink to='/Blog'>
                    <div className="block px-3 py-3 text-sm hover:rounded-t hover:bg-[#07c9e1] hover:text-white text-left font-bold border-b-[1px]">
                        Blog
                    </div>
                </NavLink>

                <NavLink to='/News'>
                    <div className="block text-sm px-3 py-3 hover:bg-[#07c9e1] hover:text-white font-bold border-b-[1px] text-left">
                        {t('navbar.news')}
                    </div>
                </NavLink>

                <NavLink to='/shipping_support'>
                    <div className="block text-sm px-3 py-3 hover:rounded-b hover:bg-[#07c9e1] hover:text-white font-bold">
                        {t('footer.shipping_support')}
                    </div>
                </NavLink>
            </div>
        </div>
    )
}

export default ExploreContent
