import React, { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import priceData from '../../data/price.json'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons'

const tHeadContent = ["Zone", "Ville - Village ", "Tarifs (DH)"];

const MainSection = () => {
    const { t } = useTranslation();
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const rowsPerPage = 15;

    // Calculate the index of the first and last row for the current page
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;

    // Handle Next and Previous button clicks
    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const handlePreviousPage = () => {
        setCurrentPage(currentPage - 1);
    };

    // Handle search input change
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        setCurrentPage(1); // Reset current page when the search term changes
    };

    // Memoize the filtered rows based on the search term
    const filteredRows = useMemo(() => {
        return priceData.filter(item =>
            item.city.toLowerCase().includes(searchTerm.toLowerCase())
        );
    }, [searchTerm]);

    // Slice the filteredRows array to display only the rows for the current page
    const currentRows = filteredRows.slice(indexOfFirstRow, indexOfLastRow);

    return (
        <div className='container lg:px-[20px] px-4 2xl:p-0 my-14 mx-auto'>
            <div className='mb-8 mt-28 flex items-center justify-center flex-col text-center'>
                <h1 className='font-extrabold md:text-3xl text-2xl mb-4 text-[#132b38]'>{t('NationalPage.priceHeading')}</h1>
                <hr className='border-[2px] border-[#07c9e1] w-[80px]' />
                <h4 className='text-gray-400 mt-3 md:text-[16px] text-[13px]'>{t('NationalPage.priceSubHeading')}</h4>
            </div>
            <div className='lg:px-8 md:px-4'>
                <div class="">
                    <div className='flex flex-col justify-end items-end'>
                        <input
                            type="text"
                            value={searchTerm}
                            onChange={handleSearchChange}
                            placeholder={t('NationalPage.priceSearchPH')}
                            className="p-2 border border-gray-300 rounded lg:w-[300px] w-[200px] outline-none mb-2"
                        />
                    </div>
                    <table className="lg:w-full w-[100%] rounded-lg shadow-md">
                        <thead className="border-b border-decoration-blue-50 text-white bg-[#171b3e] p-4">
                            <tr>
                                {
                                    tHeadContent.map((item, index) => (
                                        <th scope="col" class="md:px-6 px-4 py-3 text-center text-sm lg:text-[15px]" key={index}>
                                            {item}
                                        </th>
                                    ))
                                }

                            </tr>
                        </thead>
                        <tbody>
                            {
                                currentRows.map((item, index) => (
                                    <tr class="bg-white text-[#666666] border-b" key={index}>

                                        <td class="px-6 py-4 text-center">
                                            {item.zone}
                                        </td>
                                        <td class="px-6 py-4 text-center">
                                            {item.city}
                                        </td>
                                        <td class="px-6 py-4 text-center">
                                            {item.price}
                                        </td>
                                    </tr>
                                ))
                            }

                        </tbody>
                    </table>
                </div>
                <div className='lg:flex'>
                    <div className='mt-4 lg:w-[50%]'>
                        <p className=''>
                            <FontAwesomeIcon icon={faAsterisk} className='pl-3 pr-2 text-sm text-[#07c9e1]'></FontAwesomeIcon>
                            <span className='text-[#666666] italic text-[10px]'>Tarifs non contractuels</span>
                        </p>
                        <p className=''>
                            <FontAwesomeIcon icon={faAsterisk} className='pl-3 pr-2 text-sm text-[#07c9e1]'></FontAwesomeIcon>
                            <span className='text-[#666666] italic text-[10px]'>Les tarifs varient selon le poids et la destination</span>
                        </p>
                    </div>
                    <div className="mt-4 lg:w-[50%] flex justify-end">
                        <button
                            className="px-4 py-2 bg-gray-200 text-gray-800 rounded mr-2"
                            onClick={handlePreviousPage}
                            disabled={currentPage === 1} // Disable Previous button on first page
                        >
                            {t('NationalPage.pricePrevious')}
                        </button>
                        <button
                            className="px-6 py-2 bg-[#07c9e1] text-white rounded"
                            onClick={handleNextPage}
                            disabled={indexOfLastRow >= priceData.length} // Disable Next button on last page
                        >
                            {t('NationalPage.priceNext')}
                        </button>
                    </div>

                </div>

            </div>

        </div>
    )
}

export default MainSection
