import React from 'react'
import { useLocation } from 'react-router-dom';
import MainLayout from '../../layouts/MainLayout'
import TrackResults from '../../componenets/TrackingStatus/TrackResults';

const TrackingStatus = () => {
    // Get the location object from React Router
    const location = useLocation();
    // Access the data passed via state
    const results = location.state?.results;

    return (
        <MainLayout>
            <TrackResults data={results} />
        </MainLayout>
    )
}

export default TrackingStatus
