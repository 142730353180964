import React from 'react'

const Provinces = ({ region }) => {
    return (
        <div className='container lg:px-[20px] px-4 2xl:p-0 lg:my-16 my-8 mx-auto'>
            <div className='flex items-center justify-center px-4 flex-col mb-8'>
                <h1 className='font-extrabold text-center md:text-3xl text-2xl mb-4'>{region.titleOfList}</h1>
                <hr className='border-[2px] border-[#07c9e1] w-[100px]' />
            </div>

            <div className='grid lg:grid-cols-4 md:grid-cols-2 lg:p-8 md:p-4 mt-4 md:gap-8 gap-14'>
                {
                    region.provinces.map((province, index) => (
                        <div className='shadow-lg p-4 ' key={index}>
                            <h4 className='font-bold text-center text-[18px] pb-8 pt-3 text-[#0f173e]'>{province.province}</h4>
                            <p className='pb-4 text-[#666666] text-center'>{province.details}</p>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default Provinces
