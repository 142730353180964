import React from 'react'
import { Link } from 'react-router-dom'
import longView from '../../../assets/images/articles/horizonEcommerce/pexels-karolina-grabowska-4968391-1024x683.jpg'

const Content = () => {
    return (
        <div className='container lg:px-[20px] px-4 2xl:p-0 mx-auto'>
            <div className='flex items-center justify-center flex-col'>
                <h1 className='font-extrabold lg:text-3xl text-center text-2xl text-[#132b38]'>The move to mobile continues</h1>
            </div>
            <div className='lg:p-8 md:p-4 py-6 text-[#666666] text-justify'>
                <p className='pb-4'>Platforms like Facebook, Instagram, and TikTok are becoming key entry points for online shoppers - creating an increasingly important 'social commerce' trend. Artificial intelligence will boost it further, giving e-tailers tools to convert user behavior into personalized ads and offerings.</p>

                <p className='pb-4'>Those AI tools are rapidly changing the game, providing more personalized shopping experiences that tech-savvy Gen Z is embracing. They demand a seamless, dynamic, and flexible shopping experience right from their smartphones that doesn't require platform-hopping.</p>

                <p>Mobile phones continue to be the primary tool for online shopping, especially among younger users. According to the <Link to='https://www.pewresearch.org/short-reads/2022/11/21/for-shopping-phones-are-common-and-influencers-have-become-a-factor-especially-for-young-adults/' className='text-[#07c9e1] underline' target='_blanck'>Pew Research Center</Link> , three-quarters of all US adults regularly shop on their mobile phones. The figure is higher among those under 50. </p>
            </div>


            <div className='flex items-center justify-center flex-col lg:px-8 pb-0 py-6'>
                <h1 className='font-extrabold lg:text-3xl text-center text-2xl text-[#132b38]'>Going all-in on e-commerce</h1>
            </div>
            <div className='lg:p-8 md:px-4 py-6 text-[#666666] text-justify'>
                <p className='pb-4'>Ultimately, there is no way around e-commerce, but it must be simple and affordable. Online retailers must combine high service quality with reliable delivery and competitive prices.</p>

                <p className='pb-4'><Link to="https://www.mckinsey.com/capabilities/growth-marketing-and-sales/our-insights/becoming-indispensable-moving-past-e-commerce-to-next-commerce" target='_blanck' className='text-[#07c9e1] underline'>McKinsey</Link> warns that companies shouldn't get complacent, otherwise they risk missing out on the next e-commerce wave. That means they must adapt. With forecasts projecting higher online revenues, many businesses are investing heavily but still see e-commerce as a 'bolt-on' to the main business - a view that's looking increasingly outdated.</p>

                <p>Instead, you have to make yourself indispensable, which means fully committing to e-commerce but not limiting yourself to just selling a range of products. Instead, you should assist customers with all aspects related to your products. For example, if you sell running shoes, try offering registration for the next local marathon, nutrition plans, or connections to local running groups.</p>
            </div>

            <div className='flex items-center justify-center flex-col lg:p-8 md:p-4'>
                <h1 className='font-extrabold lg:text-3xl text-center text-2xl text-[#132b38]'>The long view: Logistics and sustainability lead to new opportunities</h1>
            </div>
            <div className='lg:p-8 md:p-4 text-[#666666] text-justify'>

                <img src={longView} alt='longView' className='rounded md:w-[50%] w-[100%] h-[200px] lg:h-[350px] my-4 md:my-0 float-right' />

                <p className='pb-4 lg:pt-0 mb-4 lg:leading-loose'>Looking ahead, logistics and sustainability will also affect how the e-commerce landscape evolves - and become a key differentiator as consumers demand greener products. About  12-20%  of e-commerce costs come from logistics, which cuts into margins. And supply chains are a big part of the carbon footprint.</p>

                <p className='pb-4 lg:pt-0 mb-4 lg:leading-loose'>Optimized supply chains leveraging data and AI will help companies achieve their sustainability goals. Circular business models are emerging rapidly, requiring sustainable reverse logistics solutions. This is opening up opportunities for both retailers and logistics providers, which is why we expect long-term growth in both B2C and B2B e-commerce as well as a shift from large bulk shipments to smaller customized deliveries</p>



            </div>
        </div>
    )
}

export default Content
